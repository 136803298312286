const examItems = {
  namespaced: true,
  state: () => ({
    all: [],
    item: null,
    restrictions: [],
  }),
  getters: {
    getId: (state) => (id) => {
      return state.all.find((item) => item.id === parseInt(id));
    },
    byExamId: (state) => (examId) => {
      examId = parseInt(examId);
      return state.all.filter((item) => item.exam_id === examId);
    },
    restrictions: (state) => (itemId) => {
      return state.restrictions.filter( ({exam_item_id}) => exam_item_id == itemId )
    }
  },
  mutations: {
    add(state, newItem) {
      state.all.push(newItem);
    },
    all(state, items) {
      state.all = items;
    },
    clear(state) {
      state.all = []
      state.item = null
    },
    delete(state, item) {
      const index = state.all.findIndex(({ id }) => item.id === id);
      state.all.splice(index, 1);
    },
    item(state, item) {
      const index = state.all.findIndex(({ id }) => item.id === id);
      if (index < 0) {
        state.all.push(item);
      } else {
        state.all.splice(index, 1, item);
      }
    },
    itemRestrictions(state, restrictions) {
      for( let restriction of restrictions ) {
        const index = state.restrictions.findIndex(({ id }) => restriction.id === id);
        if (index < 0) {
          state.restrictions.push(restriction);
        } else {
          state.restrictions.splice(index, 1, restriction);
        }
      }
    },
    itemRestriction(state, restriction) {
      const index = state.restrictions.findIndex(({ id }) => restriction.id === id);
      if (index < 0) {
        state.restrictions.push(restriction);
      } else {
        state.restrictions.splice(index, 1, restriction);
      }
    },
    removeItemRestriction(state, restriction) {
      const index = state.restrictions.findIndex(({ id }) => restriction.id === id);
      if (index > -1) {
        state.restrictions.splice(index, 1);
      }
    },
  },
  actions: {
    async create({ rootState, commit }, {data, examId}) {
      try {
        const results = await rootState.axios.post(
          `${process.env.VUE_APP_API_HOST}/exams/${examId}/items`,
          data
        );
        commit("add", results.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async edit({ rootState, commit }, { exam, itemId, item }) {
      const results = await rootState.axios.put(
        `${process.env.VUE_APP_API_HOST}/exams/${exam}/items/${itemId}`,
        item
      );
      commit("item", results.data.data);
    },
    async delete({ rootState, commit }, {exam_id, id} ) {
      try {
        const results = await rootState.axios.delete(
          `${process.env.VUE_APP_API_HOST}/exams/${exam_id}/items/${id}`
        );
        commit("delete", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async getAll({ rootState, commit }, exam) {
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/${exam}/items`
        );
        commit("all", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async getId({ rootState, commit }, {exam, id}) {
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/${exam}/items/${id}`
        );
        commit("item", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async getRestrictions({rootState, commit}, itemId) {
      try {
        const results = await rootState.axios.get(`${process.env.VUE_APP_API_HOST}/exams/items/${itemId}/restrictions`);
        commit("itemRestrictions", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async postRestriction({rootState, commit}, {itemId, blocked, combination}) {
      try {
        const results = await rootState.axios.post(
          `${process.env.VUE_APP_API_HOST}/exams/items/${itemId}/restrictions`,
          { blocked, combination }
        );
        commit("itemRestriction", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async deleteRestriction({rootState, commit}, restrictionId) {
      try {
        const results = await rootState.axios.delete(
          `${process.env.VUE_APP_API_HOST}/exams/items/restrictions/${restrictionId}`,
        );
        commit("removeItemRestriction", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
  },
};

export default examItems;
