const examGroups = {
  namespaced: true,
  state: () => ({
    all: [],
    group: null,
  }),
  getters: {
    getId: (state) => (id) => {
      return state.all.find((group) => group.id === parseInt(id));
    },
    exam: (state) => (examId) => {
      examId = parseInt(examId);
      return state.all.filter((group) => group.exam_id === examId);
    },
  },
  mutations: {
    add(state, newGroup) {
      state.all.push(newGroup);
    },
    all(state, groups) {
      state.all = groups;
    },
    clear(state) {
      state.all = []
      state.group = null
    },
    delete(state, group) {
      const index = state.all.findIndex(({ id }) => group.id === id);
      state.all.splice(index, 1);
    },
    group(state, group) {
      const index = state.all.findIndex(({ id }) => group.id === id);
      if (index < 0) {
        state.all.push(group);
      } else {
        state.all.splice(index, 1, group);
      }
    },
  },
  actions: {
    async create({ rootState, commit }, { exam, newGroup }) {
      try {
        const results = await rootState.axios.post(
          `${process.env.VUE_APP_API_HOST}/exams/${exam}/classes`,
          newGroup
        );
        commit("add", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async delete({ rootState, commit }, { exam, examClass }) {
      try {
        const results = await rootState.axios.delete(
          `${process.env.VUE_APP_API_HOST}/exams/${exam}/classes/${examClass}`
        );
        commit("delete", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async addClassifier({ rootState, commit }, { examClass, classifierId }) {
      try {
        const results = await rootState.axios.post(
          `${process.env.VUE_APP_API_HOST}/exams/classes/${examClass}/classifiers`,
          {user_id: classifierId}
        );
        commit("group", results.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async remClassifier({ rootState, commit }, { classifierId }) {
      try {
        const results = await rootState.axios.delete(
          `${process.env.VUE_APP_API_HOST}/exams/classes/classifiers/${classifierId}`,
        );
        commit("group", results.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async edit({ rootState, commit }, { examId, examClass, group }) {
      try {
        console.log(group)
        const results = await rootState.axios.put(
          `${process.env.VUE_APP_API_HOST}/exams/${examId}/classes/${examClass}`,
          group
        );
        commit("group", results.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getAll({ rootState, commit }, examId) {
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/${examId}/classes`
        );
        commit("all", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async getId({ rootState, commit }, { exam, examClass }) {
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/${exam}/classes/${examClass}`
        );
        commit("group", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
  },
};

export default examGroups;
