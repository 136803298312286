const EMPTY_USER = {
  name: "",
  created_at: "",
  updated_at: "",
  last_logged_in_at: "",
  role: 0,
  email: "",
}

const users = {
  namespaced: true,
  state: () => ({
    all: [],
    user: null,
  }),
  getters: {
    getId: (state) => (id) => {
      return state.all.find( user => user.id === parseInt(id) )
    },
    withId: (state) => (ids) => {
      return state.all.filter( user => ids.includes(user.id) )
    },
  },
  mutations: {
    add(state, newUser) {
      state.all.push(newUser);
    },
    all(state, users) {
      state.all = users;
    },
    clear(state) {
      state.all = []
      state.user = null
    },
    delete(state, user){
      const index = state.all.findIndex( ({id}) => user.id === id ) 
      state.all.splice(index, 1)
      
    },
    user(state, user) {
     const index = state.all.findIndex( ({id}) => user.id === id ) 
     if( index < 0) {
      state.all.push(user)
     }else{
      state.all.splice(index, 1, user)
     }
    },
  },
  actions: {
    async create({ rootState, commit }, newUser) {
      try {
        const results = await rootState.axios.post(
          `${process.env.VUE_APP_API_HOST}/users`,
          newUser
        );

        commit("add", results.data);

      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async delete({rootState, commit}, id) {
      try{
        const results = await rootState.axios.delete(
          `${process.env.VUE_APP_API_HOST}/users/${id}`
        );
        commit('delete', results.data.data)

      }catch (error){
        console.log('ERROR: ' + error)
      }

    },
    async edit({rootState, commit}, {id, user}) {
      if(user.password == ''){
        delete(user.password)
      }
      console.log(user)
      try{
        const results = await rootState.axios.put(
          `${process.env.VUE_APP_API_HOST}/users/${id}`, user
        );
        commit("user", results.data.data); 

      }catch (error){
        console.log('ERROR: ' + error)
      }
    },
    async getAll({ rootState, commit }) {
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/users`
        );
        commit("all", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async getId({ getters, dispatch }, id) {
      if( getters.getId(id) == null ) {
        dispatch("fetchId", id)
      }
    },
    async fetchId({ rootState, commit, dispatch }, id) {
      const emptyUser = {...EMPTY_USER, id}
      commit("user", emptyUser)
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/users/${id}`
        );
        commit("user", results.data.data);
      } catch (error) {
        commit("delete", emptyUser)
        console.log("ERROR: " + error);
        setTimeout(() => dispatch("fetchId", id), 1000)
      }
    },
    async import({rootState}, {file}) {
      try {
        let formData = new FormData()
        formData.append("file", file)
        const res = await rootState.axios.post(
          `${process.env.VUE_APP_API_HOST}/users/import`,
          formData,
          {headers: {
            'Content-Type': 'multipart/form-data'
          }}
        );
        console.log(res)
      } catch(error) {
        console.log("ERROR:" + error)
      }
    },
  },
};

export default users;
