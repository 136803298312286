<template>
    <div class="content-exams-create">
        <BubbleContent title="Adicionar Prova" :buttons=buttons @submit="$store.dispatch('exams/create', newExamData)">
            <template #form>
                <InputContent label="Nome" v-model="name" />
                <InputContent label="Tabela" v-model="table" />
                <InputContent label="Coluna Id de Aluno" v-model="studentId" />
                <InputSelectContent label="Tipo" :options="options" choose="Selecctionar Tipo" v-model="kind" />
                <InputContent label="Começo" type="datetime-local" v-model="starts_at" :choose="date" />
                <InputContent label="Fim" type="datetime-local" v-model="ends_at" :choose="date" />
            </template>
        </BubbleContent>

    </div>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import InputContent from '../Inputs/InputContent.vue';
import InputSelectContent from '../Inputs/InputSelectContent.vue';
import moment from 'moment'

function formatDate(date) {
    return date.format("YYYY-MM-DD HH:mm:ss")
}

export default {
    components: {
        BubbleContent,
        InputContent,
        InputSelectContent,
    },
    data() {
        return {
            name: "",
            table: "",
            studentId: "",
            kind: '0',
            starts_at: '',
            ends_at: '',
            buttons: [
                { text: 'Gravar', type: 'submit' },
            ],
            options: [
                { text: 'Normal', value: '0' },
                { text: 'Práctica', value: '1' },
            ],
            date: new Date().toLocaleString()
        };
    },
    computed: {
        endsAtDate() {
            return formatDate(moment(this.ends_at))
        },
        lastExam() {
            return this.$store.getters['exams/last']
        },
        startsAtDate() {
            return formatDate(moment(this.starts_at))
        },
        newExamData() {
            return {
                name: this.name,
                table: this.table,
                student_col:  this.studentId,
                kind: this.kind,
                starts_at: this.startsAtDate,
                ends_at: this.endsAtDate,
            }
        },
    },
    watch: {
        lastExam() {
            if( this.lastExam.created ) {
                this.$router.push({name: 'ExamInfo', params: {id: this.lastExam.id}})
            }
        }
    },
    mounted() {
        this.starts_at = formatDate(moment());
        this.ends_at = formatDate(moment().add(1, "month"));
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';


.content-exams-create {
    margin: 20px;
    color: $black;

    :deep .field {
        margin-bottom: 1.5em;

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }


        }
    }

}
</style>