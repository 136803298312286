<template>
    <div class="content-exams-info">
        <ExamInfoClassification v-if="exam" :id="id"/>
        <br>
        <ExamInfoSupervisorClassifiers v-for="group in groups" :key="group.id" :id="group.id"/>
    </div>
</template>

<script>
import ExamInfoClassification from './ExamInfoClassification.vue';
import ExamInfoSupervisorClassifiers from './ExamInfoSupervisorClassifiers.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        ExamInfoClassification,
        ExamInfoSupervisorClassifiers,
    },
    computed: {
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        groups() {
            return this.$store.getters['examGroups/exam'](this.id)
        }
    },
    mounted() {
        // this.$store.dispatch('examGroups/getAll', this.id)
    }

}
</script>

<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';


.content-exams-info {
    margin: 20px;
}
</style>