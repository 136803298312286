const itemCodes = {
  namespaced: true,
  state: () => ({
    all: [],
    code: null,
  }),
  getters: {
    getId: (state) => (id) => {
      return state.all.filter((code) => code.exam_item_id === parseInt(id));
    },
  },
  mutations: {
    add(state, newCode) {
      state.all.push(newCode);
    },
    all(state, codes) {
      state.all = codes;
    },
    clear(state) {
      state.all = []
      state.code = null
    },
    code(state, code) {
      const index = state.all.findIndex(({ id }) => code.id === id);
      if (index < 0) {
        state.all.push(code);
      } else {
        state.all.splice(index, 1, code);
      }
    },
    delete(state, code) {
      const index = state.all.findIndex(({ id }) => code.id === id);
      state.all.splice(index, 1);
    },
  },
  actions: {
    async create({ rootState, commit }, { newCode, item_id }) {
      try {
        const results = await rootState.axios.post(
          `${process.env.VUE_APP_API_HOST}/exams/items/${item_id}/codes`,
          newCode
        );
        commit("add", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async delete({ rootState, commit }, { item_id, code_id }) {
      try {
        const results = await rootState.axios.delete(
          `${process.env.VUE_APP_API_HOST}/exams/items/${item_id}/codes/${code_id}`
        );
        commit("delete", results.data.data);
      } catch (error) {
        console.log('ERROR: ' + error);
      }
    },
    async edit ({rootState, commit}, {item_id, code_id, code}) {
      const results = await rootState.axios.put(
        `${process.env.VUE_APP_API_HOST}/exams/items/${item_id}/codes/${code_id}`,
        code
      );
      commit('code', results.data.data)
    },
    async getAll({ rootState, commit }, { item }) {
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/items/${item}/codes`
        );
        commit("all", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async getId({ rootState, commit }, { item, code }) {
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/items/${item}/codes/${code}`
        );
        commit("code", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
  },
};

export default itemCodes;
