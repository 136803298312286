<template>
    <div class="content-users-edit">
        <BubbleContent title="Alterar" :buttons=buttons @submit="$store.dispatch('users/edit', {id, user: {name, email, password}})">
            <template #form>
                <InputContent label="Nome" v-model="name" />
                <InputContent label="Email" type="email" v-model="email" />
                <InputContent label="Password" type="password" v-model="password" />
                <InputSelectContent label="Tipo" :options="options" v-model="selected"/>
            </template>
        </BubbleContent>
    </div>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import InputContent from '../Inputs/InputContent.vue';
import InputSelectContent from '../Inputs/InputSelectContent.vue';


export default {
    components: {
        BubbleContent,
        InputContent,
        InputSelectContent,
    },
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            name: "",
            email: "",
            password: "",
            buttons: [
                { text: 'Editar', type: 'submit' },
            ],
            options: [
                {text: 'Não Definido', value: '0'},
                {text: 'Admnistrador', value: '1'},
                {text: 'IAVE', value: '2'},
            ],
        };
    },
    computed: {
        newUserData() {
            return {
                name: this.name,
                email: this.email,
                password: this.password
            }
        },
        user(){
            return this.$store.getters['users/getId'](this.id)
        },
    },
    watch: {
        user: {
            immediate: true,
            handler(){
                if(this.user != null){
                    this.name = this.user.name
                    this.email = this.user.email
                }
            },
        }
    },
    mounted() {
        if(this.user == null) {
            this.$store.dispatch('users/getId', this.id)
        }
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.content-users-edit {
    margin: 20px;
    color: $black;

    :deep .field {
        margin-bottom: 1.5em;

        input {
            border-radius: 0;
            background-color: $white;
            border: none;
            border-bottom: 4px solid $secondary-color;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            overflow: visible;
            font-family: sans-serif;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }
        }

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }


        }
    }
}
</style>