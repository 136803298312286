<template>
    <div class="content-exams-items">
        <ExamItemsList class="exams-items-items" :id="id"/>
        <BubbleContent class="exams-items-import" title="Importar" :buttons=importItemButton>
            <InputContent type="file" />
        </BubbleContent>
        <ExamCreateItem class="exams-items-add" :id="id" :columns="columns"/>
    </div>
</template>
<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';
import InputContent from '../../Inputs/InputContent.vue';
import ExamItemsList from './ExamItemsList.vue';
import ExamCreateItem from './ExamCreateItem.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        BubbleContent,
        InputContent,
        ExamItemsList,
        ExamCreateItem,
    },
    data() {
        return {
            importItemButton: [
                { text: 'Importar', type: 'submit' },
            ],
            columns: [],
        };
    },
    async mounted() {
        const res = await this.$store.state.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/${this.id}/columns`
        )
        this.columns = res.data.data;
    }
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';


.content-exams-items {
    margin: 20px;
    color: $black;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
        "exams-items-items exams-items-items"
        "exams-items-import exams-items-add";


    .exams-items-items {
        grid-area: exams-items-items;
    }

    .exams-items-import {
        grid-area: exams-items-import;

    }

    .exams-items-add {
        grid-area: exams-items-add;
    }

    :deep .field {
        margin-bottom: 1.5em;

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }


        }
    }

}
</style>