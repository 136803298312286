<template>
    <LoginBody>
        <FormLogin button="Entrar" />
    </LoginBody>
</template>

<script>
import LoginBody from '@/components/LoginBody/LoginBody.vue';
import FormLogin from '@/components/LoginBody/FormLogin/FormLogin.vue';

export default {
    props: {
        token: {default: null},
    },
    components: {
        LoginBody,
        FormLogin,
    },
    data() {
        return {};
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser
        },
    },
    watch: {
        token: {
            immediate: true,
            handler() {
                if( this.token != null ) {
                    this.$store.dispatch('loginWithToken', this.token)
                }
                console.log(this.token)
            }
        },
        currentUser: {
            immediate: true,
            handler() {
                // fez login com sucesso
                if (this.currentUser != null) {
                    this.$router.push(this.$store.state.afterLoginRoute)
                }
            }
        }
    },
    mounted() {
        console.log(this.$route.query)
    }
}

</script>

<style lang="scss" scoped>
@import '../assets/utilities/_variables.scss';

.lg {
    &-background {
        max-height: 100vh;
        height: 100vh;
        margin: 0;

        &.imageCover {
            background-image: url('../assets/images/bg.jpg');
            background-size: cover;
            background-position: center;
        }
    }

    &-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: auto;
        width: 90%;
        max-width: 500px;
        background-color: rgba(255, 255, 255, 0.65);
        border-radius: 10px;
        color: $white;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-logo-container {
        margin: 0;
        padding: 25px;
        background: rgba(255, 255, 255, 0.65);
        text-align: center;
        width: 100%;
    }

}
</style>
