<template>
    <BubbleContent title="Importar" :buttons="buttons" @submit="submit">
        <template #form>
            <div class="text">
                <p v-for="name in names" :key="name.text">
                    <span> | </span>
                    {{ name.text }}
                    <span> | </span>
                </p>
            </div>
            <InputFile button="Utilizadores" label="Selecionar ficheiro" v-model="files" />
        </template>
    </BubbleContent>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import InputFile from '../Inputs/InputFile.vue';

export default {
    props: {
        names: {
            default: null,
        }
    },
    components: {
        BubbleContent,
        InputFile,
    }, data() {
        return {
            buttons: [
                { text: 'Importar', type: 'submit' }
            ],
            files: [],
        };
    },
    methods: {
        submit() {
            this.$store.dispatch('users/import', {
                file: this.file,
            })
        },
    },
    computed: {
        file() {
            return this.files[0]
        }
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.text {
    display: flex;
    margin: 50px 0 20px 0;

    p {
        margin: 0 5px 0 5px;
    }
}
</style>