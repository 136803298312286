<template>
    <div class="content-users-create">
        <BubbleContent title="Adicionar Utilizador" :buttons=buttons @submit="$store.dispatch('users/create', newUserData)">
            <template #form>
                <InputContent label="Nome" v-model="name" />
                <InputContent label="Email" type="email" v-model="email" />
                <InputContent label="Password" type="password" v-model="password" />
                <InputSelectContent label="Nível de acesso" :options="options" :choose="choose"/>
            </template>
        </BubbleContent>
        <br>
        <UserImport :names="names"/>
    </div>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import UserImport from '../TabUser/UserImport.vue'
import InputContent from '../Inputs/InputContent.vue';
import InputSelectContent from '../Inputs/InputSelectContent.vue';

export default {
    components: {
        BubbleContent,
        UserImport,
        InputContent,
        InputSelectContent,
    },
    data() {
        return {
            name: "",
            email: "",
            password: "",
            buttons: [
                { text: 'Gravar', type: 'submit' },
            ],
            options: [
                {text: 'Não Definido', value: '0'},
                {text: 'Admnistrador', value: '1'},
                {text: 'IAVE', value: '2'},
            ],
            choose: 'Escolha uma opcção',
            names: [
                { text: 'name'},
                { text: 'email'},
                { text: 'password'},
                { text: 'school_code'},
            ]
        };
    },
    computed: {
        newUserData() {
            return {
                name: this.name,
                email: this.email,
                password: this.password
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.content-users-create {
    margin: 20px;
    color: $black;

    :deep .field {
        margin-bottom: 1.5em;

        #role {
            color: $black;
            margin: 0;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            border: none;
            font-size: 100%;
            margin: 0;
            border-bottom: 4px solid $secondary-color;

            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;
            }


        }
    }

}
</style>