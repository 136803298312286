<template>
    <BubbleContent title="Distribuir Classificações" 
        :buttons="button"
        @submit.prevent="$store.dispatch('examDistribution/distribute', { exam: id, attribution: attributionData })">
        <div class="distribution-items">
            <p>Classificador</p>
            <p v-for="item in items" :key="item.id">
                <span>{{ item.name }}</span>
            </p>
            <p>Total</p>
            <p>Esforço</p>
        </div>
        <ExamDistributionGroupClassifications v-for="group in groups" :key="group.id" :id="group.id" />
        <div class="classifications-total">
            <p class="total">Total</p>
            <p v-for="item in items" :key="item.id">
                <span>{{ item.multiple_params }}</span> 
                <!-- //!check the right parameter -->
            </p>
            <p>Valor</p>
            <p>Valor</p>
        </div>
    </BubbleContent>
</template>

<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue'
import ExamDistributionGroupClassifications from './ExamDistributionGroupClassifications.vue'

export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        BubbleContent,
        ExamDistributionGroupClassifications,
    },
    data() {
        return {
            button: [
                { text: 'Distribuir', type: 'submit' },
            ],
            attributions: {},
        }
    },
    methods: {

    },
    computed: {
        attributionData() {
            let attributions = {}
            for (let item of this.items) {
                attributions[item.id] = []
                for (let groupId in this.attributions) {
                    let groupAttributions = this.attributions[groupId]
                    let itemAttributions = groupAttributions[item.id]
                    if (itemAttributions == null) {
                        continue
                    }
                    attributions[item.id] = [
                        ...attributions[item.id],
                        ...itemAttributions
                    ]
                }
            }
            return attributions
        },
        groups() {
            return this.$store.getters['examGroups/exam'](this.id)
        },
        items() {
            return this.$store.state.examItems.all
        },
    },
    mounted() {
        this.$store.dispatch('examItems/getAll', this.id)
        this.$store.dispatch('examGroups/getAll', this.id)
    },

}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';


.distribution-items {
    border-bottom: 2px solid $primary-color;
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;


    
}

.classifications-total {
    border-top: 1px solid black;
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;

    .total{
        margin-right: 40px;
    }
}

</style>