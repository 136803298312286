import UsersPage from "../views/UsersPage.vue";
import UsersList from "../components/SCOI/TabUser/UsersList.vue";
import UserEdit from "../components/SCOI/TabUser/UserEdit.vue";
import UserCreate from "../components/SCOI/TabUser/UserCreate.vue";

const users = [
  {
    path: "/users",
    component: UsersPage,
    children: [
      {
        path: "",
        name: "UsersList",
        component: UsersList,
      },
      {
        path: ":id",
        name: "UserEdit",
        component: UserEdit,
        props: true,
      },
      {
        path: "create",
        name: "UserCreate",
        component: UserCreate,
      },
    ],
  },
];

export default users;
