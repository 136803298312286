<template>
    <div class="radio-field">
        <input :id="id"
            :name="name"
            v-model="newValue"
            :value="value"
            :type="type"
            ref="input"
            @input="$emit('update:modelValue', $event.target.value)">
        <label class="button-label" :for="id">{{ label }}</label>
    </div>
</template>
<script>
export default {
    props: {
        value: {required: true},
        label: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'radio',
        },
        modelValue: {
            default: '',
        },
    },
    data() {
        return {
            id: null,
            newValue: "",
        }
    },
    watch: {
        newValue() {
            console.log
            this.$emit('update:modelValue', this.newValue)
        },
    },
    mounted() {
        let r = (Math.random()).toString().replaceAll("0.", "")
        this.id = r
        if( this.value == this.modelValue ) {
            this.$refs.input.checked = true
        }
    },

}
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

input {
    display: none;
}

.radio-field {
    display: grid;
    width: -webkit-fill-available;


    .button-label {
        display: inline-block;
        padding: 0.5em;
        cursor: pointer;
        color: $primary-color;
        border: 1px solid $primary-color;
        border-radius: 0.25em;
        background: $white;
        transition: 0.3s;
        user-select: none;
        appearance: none;       
        
        p {
            font-size: 1em;

        }

        &:hover {
            background: $primary-color;
            color: $white;
        }

        &:active {
            transform: translateY(2px);
        }
    }

    input:checked + label {
        background: $primary-color;
        color: $white;
        

        &:hover {
            background: $primary-color;
            color: $white;
        }
    }

    .hidden {
        display: none;
    }
}
</style>