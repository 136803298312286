<template>
    <ul class="options">
        <li v-for="option, index in options" :key="index" @click.prevent.stop>
            {{ option.text }}
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        options: {required: true},
    },
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
}

</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.options {
    position: absolute;
    right: -.75em;
    top: .75em;
    background: white;
    box-shadow: 0px 2px 6px rgba(black, .4);
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 9000;
    font-size: 1rem;
    overflow: hidden;
    border-radius: 4px;
    text-align: left;
    li {
        padding: 1em 2em;
        &:hover {
            background-color: rgba($primary-color, .2);
        }
    }
}


</style>