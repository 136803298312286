<template>
    <div class="params">
        <div class="param" v-for="param in params" :key="param">
            <h3 v-if="multi && param != null || !multi && param == null" v-text="param"></h3>
            <template v-if="multi && param != null || !multi && param == null">
                <p v-for="code in paramCodes[param]" :key="code.id">
                    <span>{{ code.value }} -</span>
                    <span class="item-delete"
                        @click.prevent="$store.dispatch('itemCodes/delete', { item_id: itemId, code_id: code.id })"> Remover</span>
                </p>
                <ItemCodesCreate class="params-new" :itemId="itemId" :name="param" v-if="param != null"/>
            </template>
        </div>
        <div class="newParam" v-if="multi">
            <h3>Novo parâmetro</h3>
            <InputContent class="code-input" label="Nome" v-model="newParam" :itemId="itemId" />
            <ItemCodesCreate class="params-new" :itemId="itemId" :name="newParam"/>
        </div>
    </div>
</template>
<script>
import ItemCodesCreate from './ItemCodesCreate.vue';
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';

export default {
    components: {
        ItemCodesCreate,
        InputContent,
    },
    props: {
        itemId: {
            required: true,
        },
        multi: {required: true},
    },
    data() {
        return {
            newParam: "",
        };
    },
    computed: {
        params() {
            let params = this.itemCodes.map( (code) => code.name )
            return params.filter((name, index) => params.indexOf(name) === index)
        },
        paramCodes() {
            let codes = {}
            for( let param of this.params ) {
                codes[param] = this.itemCodes.filter( ({name}) => name == param)
            }
            return codes
        },
        itemCodes() {
            return this.$store.getters['itemCodes/getId'](this.itemId)
        },
        item() {
            return this.$store.getters['examItems/getId'](this.itemId)
        },
    },
    mounted() {
    }

}
</script>
<style lang="scss" scoped>
.params {
    display: flex;
    align-items: stretch;
    gap: 10px;
    .param {
        display: flex;
        flex-direction: column;
    }
}
.params-new {
    margin-top: auto;
}
.item-delete {
    margin-top: 15px;
    color: red;
    cursor: pointer;
}
</style>