<template>
    <div class="popup-container">
        <div class="popup-close-btn" @click="close">
            <IconComponent icon="close" />
        </div>
        <p>Incluir aqui pdf de recurso</p>
        <router-link v-if="item" to="#" :href="item.criteria_url" target="_blank">
            <p class="popup-newWindow">Abrir numa nova janela</p>
        </router-link>
        <div class="content-box">
            <object v-if="item" name="Critérios" :data="item.criteria_url" type="application/pdf" width="100%" height="100%"></object>
        </div>
    </div>
</template>
<script>
import IconComponent from '@/assets/images/IconComponent.vue';

export default {
    props: {
        id: {
            required: true
        },
        itemId: {
            required: true
        },
    },
    components: {
        IconComponent,
    },
    data() {
        return {
            criteriaUrl: '',
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
    },
    computed: {
        item() {
            return this.$store.getters['examItems/getId'](this.itemId)
        },
        items() {
            return this.$store.state.examItems.all
        },
    },
    mounted() {
        if (this.item == null) {
            this.$store.dispatch('examItems/getId', { exam: this.id, id: this.itemId })
        }
    },

}
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.popup-container {
    text-align: center;
    width: 100%;
    overflow: hidden;
    background-color: $dark-border-color;
    position: relative;
    height: auto;

    .popup-close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 45px;
        height: 45px;
        filter: drop-shadow(2px 2px 4px $black) drop-shadow(1px 1px 1px $black);
        padding: 5px;
        fill: $white;
        cursor: pointer;
    }

    .popup-newWindow {
        color: $primary-color;
    }

    .content-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 10px;

    }

}
</style>