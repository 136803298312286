<template>
    <div>
        <TopContainerNav v-if="exam" :title="exam.name" :status="exam.status" :exam="exam"/>
        <TopContainerNavBody :actions=actions @submit.prevent="$store.dispatch('exams/delete', exam)" />
        <div v-if="exam">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import TopContainerNav from '@/components/SCOI/TopContainerNav/TopContainerNav.vue';
import TopContainerNavBody from '@/components/SCOI/TopContainerNav/TopContainerNavBody.vue';
export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        TopContainerNav,
        TopContainerNavBody,
    },
    data() {
        return {
            actions: [
                { text: 'Voltar', route: '/exams', show: true  },
                { text: 'Informação', route: { name: "ExamInfo", params: { id: this.id } }, show: true },
                { text: 'Distribuir', route: { name: "ExamDistribution", params: { id: this.id } }, show: true },
                { text: 'Classificadores', route: { name: "ExamClassifiers", params: { id: this.id } }, show: true },
                // { text: 'Grupos', route: { name: "ExamGroups", params: { id: this.id } }, show: true },
                { text: 'Itens', route: { name: "ExamItems", params: { id: this.id } }, show: true },
                // { text: 'Respostas', route: { name: "ExamAnswers", params: { id: this.id } }, show: true },
                // { text: 'Estatísticas', route: { name: "ExamStatistics", params: { id: this.id } }, show: true },
                { text: 'Editar', route: { name: "ExamEdit", params: { id: this.id } }, show: true },
            ]
        };
    },
    computed: {
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
    },
    mounted() {
        this.$store.dispatch('exams/getId', this.id)
    },

}
</script>
<style lang="scss" scoped></style>