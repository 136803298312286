<template>
    <BubbleContent :title="'Supervisor:' + ' ' +supervisor.name">
        <template #form>
            <p> Respostas Atríbuidas: 
                <span>{{ group.supervisor_classifications }}</span>
            </p>
            <p> Respostas Atríbuidas não classificadas: 
                <span>{{ group.supervisor_classifications - group.supervisor_classified }}</span>
            </p>
            <p> Respostas classificadas: 
                <span>{{ group.supervisor_classified }}</span>
            </p>
        </template>
    </BubbleContent>
</template>

<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        BubbleContent,
    },
    computed: {
        group() {
            return this.$store.getters['examGroups/getId'](this.id)
        },
        supervisor() {
            if (this.group) {
                return this.$store.getters['users/getId'](this.group.user_id)
            }
            return null
        },
    },
    
}
</script>

<style lang="scss" scoped>
    
</style>