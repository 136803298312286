<template>
    <div class="file-field">
        <label class="file-label" :for="id">
            {{ label }}
            <template v-if="filename">
                : {{ filename }}
            </template>
        </label>
        <input class="file-input" :id="id" :type="type" @input="input = $event.target.files" multiple="0" />
    </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'file',
        },
        modelValue: {
            default: '',
        },
        field: {
            default: '',
        },
    },
    emits: [
        'update:modelValue',
    ],
    data() {
        return {
            id: "input",
            input: [],
        }
    },
    computed: {
        filename() {
            if( this.input.length == 0 ) {
                return null
            }
            if( this.input.length == 1 ) {
                return this.input[0].name
            } 
            return `${this.input.length} ficheiros a carregar`
        }
    },
    watch: {
        input() {
            this.$emit('update:modelValue', this.input)
        }
    },
    mounted() {
        let r = (Math.random()).toString().replaceAll("0.", "")
        this.id = r
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.file-field {
    margin-bottom: 1.5em;
    margin-top: 20px;
    
    .file-label{
        background-color: rgba(23, 121, 186, 0);
        color: $primary-color;
        display: inline-block;
        width: 100%;
        
        cursor: pointer;
        padding: 0.4em;
        transition: .2s ease-out;
        transition-property: background-color, color, border-color;
        border: 1px solid $primary-color;
    }
    .file-input{
        display: none;
    }
    
}
</style>