<template>
    <div class="distribution-content">
        <ExamDistributionItems :id="id"/>
        <br>
        <ExamDistributionClassifications :id="id"/>
    </div>
</template>

<script>
import ExamDistributionItems from './ExamDistributionItems.vue';
import ExamDistributionClassifications from './ExamDistributionClassifications.vue';


export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        ExamDistributionItems,
        ExamDistributionClassifications,
    },
    data() {
        return {
            button: [
                { text: 'Distribuir', type: 'submit' },
            ],
            attributions: {},
        };
    },
    methods: {
        attributeItems(value, groupId) {
            this.attributions[groupId] = value
        },
    },
    computed: {
        attributionData() {
            let attributions = {}
            for (let item of this.items) {
                attributions[item.id] = []
                for (let groupId in this.attributions) {
                    let groupAttributions = this.attributions[groupId]
                    let itemAttributions = groupAttributions[item.id]
                    if (itemAttributions == null) {
                        continue
                    }
                    attributions[item.id] = [
                        ...attributions[item.id],
                        ...itemAttributions
                    ]
                }
            }
            return attributions
        },
        // groups() {
        //     return this.$store.getters['examGroups/exam'](this.id)
        // },
        items() {
            return this.$store.state.examItems.all
        },
    },
    mounted() {
        this.$store.dispatch('examItems/getAll', this.id)
        this.$store.dispatch('examGroups/getAll', this.id)
    },

}
</script>
<style lang="scss" scoped>

.distribution-content{
    margin: 20px;
}
    
    </style>
    
    
    
    
