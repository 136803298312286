import ExamPageInfo from "../views/ExamPageInfo.vue";
import ExamInfo from "../components/SCOI/TabExams/ExamInfo/ExamInfo.vue";
import ExamEdit from "../components/SCOI/TabExams/ExamEdit.vue";
import ExamClassifiers from '../components/SCOI/TabExams/ExamClassifiers.vue'
import ExamDistribution from '../components/SCOI/TabExams/ExamDistribution/ExamDistribution.vue'
import ExamGroups from '../components/SCOI/TabExams/ExamGroups/ExamGroups.vue'
import ExamItems from '../components/SCOI/TabExams/ExamItems/ExamItems.vue'
import ExamAnswers from '../components/SCOI/TabExams/ExamAnswers/ExamAnswers.vue'
import ExamStatistics from '../components/SCOI/TabExams/ExamStatistics/ExamStatistics.vue'
import ExamItemEdit from '../components/SCOI/TabExams/ExamItems/ExamItemEdit.vue'

const examInfo = [
  {
    path: "/exam/:id",
    component: ExamPageInfo,
    props: true,
    children: [
      {
        path: "",
        name: "ExamInfo",
        component: ExamInfo,
        props: true,
      },
      {
        path: "classificadores",
        name: "ExamClassifiers",
        component: ExamClassifiers,
        props: true,
      },
      {
        path: "distribution",
        name: "ExamDistribution",
        component: ExamDistribution,
        props: true,
      },
      {
        path: "groups",
        name: "ExamGroups",
        component: ExamGroups,
        props: true,
      },
      {
        path: "items",
        name: "ExamItems",
        component: ExamItems,
        props: true,
      },
      {
        path: "items/:itemId",
        name: "ExamItemEdit",
        component: ExamItemEdit,
        props: true,
      },
      {
        path: "answers",
        name: "ExamAnswers",
        component: ExamAnswers,
      },
      {
        path: "statistics",
        name: "ExamStatistics",
        component: ExamStatistics,
      },
      {
        path: "edit",
        name: "ExamEdit",
        component: ExamEdit,
        props: true,
      },
    ],
  },
];

export default examInfo;
