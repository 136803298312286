<template>
    <BubbleContent title="Supervisionar">
        <div>
            <div class="info-classifiers-nav">
                <p v-for="column of columns" :key="column.text">
                    {{ column.text }}
                </p>
            </div>
            <div v-for="classifier in classifiers" :key="classifier.id" class="info-list-classifiers">
                <div class="info-classifiers">
                    <p>{{ classifier.name }}</p>
                    <p>TO CHECK!</p>
                    <p>TO CHECK!</p>
                    <p>TO CHECK!</p>
                    <p>{{ classifier.last_logged_in_at }}</p>
                </div>
            </div>
        </div>
        <ExamInfoClassifiersItems :id="id"/>
    </BubbleContent>
</template>

<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';
import ExamInfoClassifiersItems from './ExamInfoClassifiersItems.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        BubbleContent,
        ExamInfoClassifiersItems,
    },
    data() {
        return {
            columns: [
                { text: 'Nome' },
                { text: 'Classificadas' },
                { text: 'Em falta' },
                { text: 'Total' },
                { text: 'Última Entrada' },
            ],
        };
    },
    computed: {
        classifiers() {
            if (this.group) {
                return this.$store.getters['users/withId'](this.group.users)
            }
            return []
        },
        group() {
            return this.$store.getters['examGroups/getId'](this.id)
        },
    },
    mounted() {
        this.$store.dispatch("users/getAll");
    },

}
</script>

<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';


.info-classifiers-nav {
    padding: 1rem;
    border-bottom: 2px solid $primary-color;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    p {
        margin: 0;
        font-weight: bold;
    }
}


.info-list-classifiers {
    margin: 0;
    padding-left: 1rem;
    border-top: 1px solid gray;

    &:hover {
        background: rgba($primary-color, 0.1);
        cursor: pointer;

    }

    .info-classifiers {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(5, 1fr);

    }

}
</style>