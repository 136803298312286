<template>
    <svg @mouseover="hovering = true" @mouseleave="hovering = false" viewBox="0 0 100 100" class="icon">
        <use :xlink:href="href" :href="href" :data="href">X</use>
    </svg>
</template>

<script>
import Icon from '@/assets/images/icons.svg'
    export default {
        props: {
            icon: { required: true },
            hover: { default: null }
        },

        data() {
            return {
                hovering: false,
            }
        },

        computed: {
            href() {
                let hover = this.hover || this.icon;
                let href = Icon + "#";
                if( this.hovering ) {
                    return href + hover;
                }
                return href + this.icon;
            }
        },

        mounted() {
        }
    }
</script>
