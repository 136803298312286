<template>
    <div>
        <TopContainerNav title="Utilizadores" />
        <TopContainerNavBody :actions="actions" />
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import TopContainerNav from '@/components/SCOI/TopContainerNav/TopContainerNav.vue'
import TopContainerNavBody from '@/components/SCOI/TopContainerNav/TopContainerNavBody.vue';

export default {
    components: {
        TopContainerNav,
        TopContainerNavBody,
    },
    data() {
        return {
            actions: [
                { text: 'Utilizadores', route: '/users', show:true },
                { text: 'Adicionar', route: '/users/create', show:true },
            ]
        };
    },
}
</script>
<style lang="scss" scoped></style>