const examDistribution = {
  namespaced: true,
  state: () => ({
    all: [],
    attributions: [],
  }),
  getters: {
    attributions: (state) => (exam) => {
      let attr = state.attributions.find((a) => a.exam == exam)
      if( attr ) {
        return attr.attributions
      }
      return []
    },
  },
  mutations: {
    add(state, newAttribution) {
      state.all.push(newAttribution);
    },
    addAttributions(state, {attributions, exam}) {
      const index = state.attributions.findIndex((attr) => attr.exam == exam)
      if(index < 0) {
        state.attributions.push({exam, attributions})
      } else {
        state.attributions.splice(index, 1, {exam, attributions})
      }
    },
    classification(state, classification){
      const index = state.all.findIndex(({id}) => classification.id === id)
      if(index < 0) {
        state.all.push(classification)
      } else {
        state.all.splice(index, 1, classification)
      }
    },
    clear(state) {
      state.all = []
      state.attributions = []
    },
  },
  actions: {
    async getAttributions({rootState, commit}, {examId}) {
      try {
        const res = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/${examId}/attributions`
        )
        commit('addAttributions', {exam: examId, attributions: res.data.data})
      } catch (e) {
        console.log(e)
      }
    },
    async attribute({ rootState, commit }, {exam, newAttribution, supervisors}) {
      try {
        const results = await rootState.axios.post(`${process.env.VUE_APP_API_HOST}/exams/${exam}/attribute`,
        {item_attributions: newAttribution, supervisors})
        commit('addAttributions', {exam, attributions: results.data.data})
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async distribute ({rootState, commit}, {exam, attribution}) {
      try{
        const results = await rootState.axios.post(`${process.env.VUE_APP_API_HOST}/exams/${exam}/distribute`, attribution)
        commit('classification', results.data.data)

      } catch(error) {
        console.log('ERROR: ' + error)
      }

    },
  },
};

export default examDistribution;
