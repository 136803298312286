<template>
    <BubbleContent class="exams-items-items" title="Items">
        <div class="exams-items">
            <p v-for="column of columns" :key="column.text">{{ column.text }}</p>
        </div>
        <p v-for="item in items" :key="item.id" class="items-list">
            <router-link :to="{ name: 'ExamItemEdit', params: { itemId: item.id } }">
                <div class="items">
                    <p>{{ item.name }}</p>
                    <p>{{ item.effort }}</p>
                    <p>{{ item.overflow }}</p>
                    <p>{{ item.supervisor_distribution }}</p>
                    <p>{{ item.latex }}</p>
                    <span @click.prevent="$store.dispatch('examItems/delete', item)">Remover</span>
                </div>
            </router-link>
        </p>
    </BubbleContent>
</template>
<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';
export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        BubbleContent,
    },
    data() {
        return {
            columns: [
                { text: 'Item' },
                { text: 'Índice de Esforço' },
                { text: 'Percentagem de Sobreposição' },
                { text: 'Classificadas pelo Supervisor' },
                { text: 'Tipo' },
            ],
        };
    },
    computed: {
        items() {
            return this.$store.state.examItems.all
        },
    },
    mounted() {
        this.$store.dispatch('examItems/getAll', this.id)
    },

}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

.exams-items {
    padding: 1rem;
    border-bottom: 2px solid $primary-color;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    p {
        margin: 0;
        font-weight: bold;
    }
}

.items-list {
    margin: 0;
    padding: 1rem;
    border-top: 1px solid gray;




    &:hover {
        background: rgba($primary-color, 0.1);

    }

    .items {
        margin: 0;
        // padding-left: 1rem;
        display: grid;
        grid-template-columns: repeat(6, 1fr);



        span {
            margin-top: 15px;
            color: red;

            &:hover {
                margin-top: 12px;
                font-size: larger;
            }
        }
    }
}
</style>