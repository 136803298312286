<template>
    <div class="exams">
        <TopContainerNav title="Provas" />
        <TopContainerNavBody :actions=actions />
        <router-view class="view"></router-view>
    </div>
</template>
<script>
import TopContainerNav from '@/components/SCOI/TopContainerNav/TopContainerNav.vue';
import TopContainerNavBody from '@/components/SCOI/TopContainerNav/TopContainerNavBody.vue';
export default {
    props: {
        id: {
            default: null,
        },
        state: {
            required: true
        },
    },
    components: {
        TopContainerNav,
        TopContainerNavBody,
    },
    computed: {
        actions() {
            return [
                { text: 'Voltar', route: { name: "ClassifiersExamsList", params: { id: this.id } }, show: this.id != null },
                { text: 'Provas', route: '/exams', show: this.isAdmin },
                { text: 'Adicionar', route: '/exams/create', show: this.isAdmin },
                { text: `Classificar ( ${this.countClassify} )`, route: { name: "ClassifiersExamItems", params: { id: this.id } }, show: this.id != null },
                { text: `Gravadas ( ${this.countSaved} )`, route: { name: "ClassifiersExamSaved", params: { id: this.id } }, show: this.id != null },
                { text: `Marcadas para Revisão ( ${this.countMarkToReview} )`, route: { name: "ClassifiersExamToBeReviewd", params: { id: this.id } }, show: this.id != null },
                { text: `Rever ( ${this.countReview} )`, route: { name: "ClassifiersExamToReview", params: { id: this.id } }, show: this.id != null },
            ]
        },
        currentUser() {
            return this.$store.state.currentUser
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        isAdmin() {
            return this.currentUser.role == 1
        },
        item() {
            return this.$store.getters['examItems/getId'](this.itemId)
        },
        items() {
            return this.$store.state.examItems.all
        },
        // -------------------- GET A WAY TO OPTIMIZE THIS PART OF CODE
        classify() {
            const item = this.items.map(item => item.classifications_count[0])
            const itemFilter = item.filter(i => i !== undefined)
            return itemFilter
        },
        countClassify() {
            let sum = 0
            let classify = this.classify
            for(let i=0; i<classify.length;i++){
                sum += classify[i]
            }
            return sum
        },
        saved() {
            const item = this.items.map(item => item.classifications_count[1])
            const itemFilter = item.filter(i => i !== undefined)
            return itemFilter
        },
        countSaved() {
            let sum = 0
            let saved = this.saved
            for(let i=0; i<saved.length;i++){
                sum += saved[i]
            }
            return sum
        },
        markToReview() {
            const item = this.items.map(item => item.classifications_count[2])
            const itemFilter = item.filter(i => i !== undefined)
            return itemFilter
        },
        countMarkToReview() {
            let sum = 0
            let markToReview = this.markToReview
            for(let i=0; i<markToReview.length;i++){
                sum += markToReview[i]
            }
            return sum
        },
        review() {
            const item = this.items.map(item => item.classifications_count[3])
            const itemFilter = item.filter(i => i !== undefined)
            return itemFilter
        },
        countReview() {
            let sum = 0
            let review = this.review
            for(let i=0; i<review.length;i++){
                sum += review[i]
            }
            return sum
        },

    // -------------------- GET A WAY TO OPTIMIZE THIS PART OF CODE
    },
    mounted() {
        this.$store.dispatch('exams/getAll')
        if( this.id ) {
            this.$store.dispatch('examItems/getAll', this.id)
        }
        if (this.exam == null && this.id != null) {
            this.$store.dispatch('exams/getId', this.id)
        }
        console.log(this.classify)
    }
}
</script>

<style lang="scss" scoped>
.view {
    width: 100%;
    max-width: 100%;
}
</style>