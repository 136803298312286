<template>
        <div class="users-container">
            <div class="users-items">
                <p v-for="column of columns" :key="column.text">{{ column.text }}</p>
            </div>
            <p v-for="user in users" :key="user.id" class="list">
                <router-link :to="{ name: 'UserEdit', params: { id: user.id } }">
                    <div class="users">
                        <p>{{ user.name }}</p>
                        <p>{{ user.email }}</p>
                        <p>{{ user.nr_classificador }}</p>
                        <p>{{ user.updated_at }}</p>
                        <span @click.prevent="$store.dispatch('users/delete', user.id)">Eliminar</span>
                    </div>
                </router-link>
            </p>
        </div>
</template>
<script>

export default {
    data() {
        return {
            columns: [
                { text: 'Nome' },
                { text: 'Email' },
                { text: 'NrClassificador' },
                { text: 'Última entrada' },
            ],

        };
    },
    computed: {
        users() {
            return this.$store.state.users.all;
        },
    },
    mounted() {
        this.$store.dispatch("users/getAll");
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';


.users-container{

    background-color: $white;
    padding: 0 20px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);


.users-items {
    padding: 1rem;
    border-bottom: 2px solid $primary-color;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    p {
        margin: 0;
        font-weight: bold;
    }
}

.list {
    margin: 0;
    padding-left: 1rem;
    border-top: 1px solid gray;

    &:hover {
        background: rgba($primary-color, 0.1);

    }

    .users {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(5, 1fr);


        span {
            margin-top: 15px;
            color: red;

            &:hover {
                margin-top: 12px;
                font-size: larger;
            }


        }
    }

}
}
</style>