<template>
    <BubbleContent title="Distribuir Classificações" 
        :buttons="button"
        @submit.prevent="submit">
        <table>
            <thead>
                <tr>
                    <th class="left">
                    </th>
                    <th class="left">
                        Classificador
                    </th>
                    <th v-for="item in items" :key="item.id">
                        <span>{{ item.name }}</span>
                    </th>
                    <th>
                        Total
                    </th>
                    <th>
                        Esforço
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="classifier, classifier_id, index in classifierSelect"
                    :key="classifier_id"
                    :class="{supervisor: classifier.supervisor}">
                    <td class="right">
                        <strong v-text="index + 1"></strong>
                    </td>
                    <td class="right">
                        <span v-text="classifier.nr_classificador" @click.prevent="upgrade(classifier_id)"></span>
                    </td>
                    <td v-for="item in items" :key="item.id">
                        <DistributionButton
                            :selected="classifierSelect[classifier_id].items[item.id]"
                            @select="select(classifier_id, item.id, $event)">
                            <span v-text="classificationsNumber[classifier_id][item.id]"></span>
                        </DistributionButton>
                    </td>
                    <td v-text="classifier.classifications_total" class="right"></td>
                    <td v-text="classifier.attributed_effort" class="right"></td>
                </tr>
            </tbody>
        </table>
    </BubbleContent>
</template>

<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue'
import DistributionButton from './DistributionButton.vue'

export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        BubbleContent,
        DistributionButton,
    },
    data() {
        return {
            button: [
                { text: 'Distribuir', type: 'submit' },
            ],
            lastClick: '',
            classifierSelect: null,
            classificationsNumber: null,
        }
    },
    methods: {
        submit() {
            this.$store.dispatch('examDistribution/attribute', {
                exam: this.id,
                newAttribution: this.classifierSelect,
                supervisors: this.supervisorAttributions
            })
        },
        upgrade(classifier) {
            this.$store.dispatch('classifiers/upgrade', classifier)
        },
        select(classifier, item, e = null) {
            console.log(e)
            if( e && e.shiftKey && this.lastClick != '') {
                const lastClassifier = this.lastClick.classifier
                const lastItem = this.lastClick.item
                const lastClassifierIndex = this.classifiers.findIndex( ({id}) => parseInt(lastClassifier) == id)
                const classifierIndex = this.classifiers.findIndex( ({id}) => parseInt(classifier) == id)
                const minClassifier = Math.min(lastClassifierIndex, classifierIndex)
                const maxClassifier = Math.max(lastClassifierIndex, classifierIndex)
                const classifiers = this.classifiers.slice(minClassifier, maxClassifier+1)

                const lastItemIndex = this.items.findIndex( ({id}) => parseInt(lastItem) == id)
                const itemIndex = this.items.findIndex( ({id}) => parseInt(item) == id)
                const minItem = Math.min(lastItemIndex, itemIndex)
                const maxItem = Math.max(lastItemIndex, itemIndex)
                const items = this.items.slice(minItem, maxItem+1)

                for( let classifier of classifiers) {
                    for( let item of items ) {
                        if( lastClassifier != classifier.id || lastItem != item.id ) {
                            this.select(classifier.id, item.id)
                        }
                    }
                }
                this.lastClick = {classifier, item}
                return
            }
            this.lastClick = {classifier, item}
            this.classifierSelect[classifier].items[item] = !this.classifierSelect[classifier].items[item] 
        },
        initSelection() {
            this.classifierSelect = null
            this.classificationsNumber = null
            if( this.classifiers.length == 0 ) {
                return
            }
            let classifierSelect = {}
            let classificationsNumber = {}
            for( let classifier of this.classifiers ) {
                let items = {}
                let classifications = {}
                for( let item of this.items ) {
                    items[item.id] = false
                    classifications[item.id] = 0
                }
                classifierSelect[classifier.id] = {
                    items,
                    nr_classificador: classifier.nr_classificador,
                    supervisor: classifier.supervisor,
                    classifications_total: classifier.classifications_total,
                }
                classificationsNumber[classifier.id] = classifications
            }
            this.classifierSelect = classifierSelect
            this.classificationsNumber = classificationsNumber
        }
    },
    computed: {
        loading() {
            return this.$store.getters['classifiers/loading'](this.id)
        },
        classifiers() {
            return this.$store.getters['classifiers/exam'](this.id)
        },
        items() {
            return this.$store.state.examItems.all
        },
        attributions() {
            return this.$store.getters['examDistribution/attributions'](this.id)
        },
        supervisorAttributions() {
            let supervisors = this.classifiers.filter( ({supervisor}) => supervisor)
            let attributions = {}
            for( let supervisor of supervisors ) {
                attributions[supervisor.id] = this.classificationsNumber[supervisor.id]
            }
            return attributions
        },
    },
    watch: {
        classifiers() {
            this.initSelection()
            this.$store.dispatch('examDistribution/getAttributions', {examId: this.id})
        },
        items() {
            this.initSelection()
        },
        attributions() {
            if( this.classifierSelect != null ) {
                for( let att of this.attributions ) {
                    this.classificationsNumber[att.classifier_id][att.exam_item_id] = att.classifications_number
                    this.classifierSelect[att.classifier_id].items[att.exam_item_id] = true
                }
            }
        },
    },
    mounted() {
        this.$store.dispatch('examItems/getAll', this.id)
        this.$store.dispatch('classifiers/getAll', {examId: this.id})
    },
}

</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

table {
    width: 100%;
    border: none;
    border-collapse: collapse;
    td{
        text-align: center;
        padding: .2em;
        white-space: nowrap;
    }
    tbody {
        tr {
            &:nth-child(odd) {
                background-color: rgba($primary-color, .1);
            }
            &.supervisor {
                background-color: rgba(black, .15);
                &:nth-child(odd) {
                    background-color: rgba(black, .2);
                }
            }
        }
    }
    .right, .left {
        width: 0;
    }
    .left {
        text-align: left;
    }
    .right {
        text-align: center;
    }
}


.distribution-items {
    border-bottom: 2px solid $primary-color;
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    // display: flex;
    // justify-content: space-around;

}

.classifications-total {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    border-top: 1px solid black;
}

</style>