<template>
  <div class="body">
    <TopNavBarLogIn v-if="currentUser" class="navbar" />
    <TopNavBar v-else class="navbar" />
    <SideBarPage v-if="currentUser" class="sidebar" />
    <router-view v-if="ready" class="routerview" />
    <FooterBar class="footer" />
  </div>
</template>

<script>
import TopNavBar from './components/TopNavBar/TopNavBar.vue'
import FooterBar from '@/components/FooterBar/FooterBar.vue';
import SideBarPage from './views/SideBarPage.vue';
import TopNavBarLogIn from './components/TopNavBar/TopNavBarLogIn.vue';

export default {
  components: {
    TopNavBar,
    FooterBar,
    SideBarPage,
    TopNavBarLogIn,
  },
  data() {
    return {
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    ready() {
      return this.$store.state.checkedForUserInLocalStorage
    },
    token() {
      return this.$store.getters["user/token"]
    },
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(newUser, oldUser) {
        if( this.currentUser ) {
          this.$store.dispatch("getUser")
        }
        if( newUser == null && oldUser != null ) {
          this.$store.commit('checkLocalStorage')
          this.$router.push({name: 'entrar'})
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch('getUserFromLocalStorage')
  }
}
</script>

<style lang="scss">
@import './assets/utilities/_variables.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $black;
  background: $body-bg ;
  height: 100%;
  width: 100%;
}

#app{
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.body {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'navbar navbar'
    'sidebar routerview'
    'footer footer';

  .navbar {
    grid-area: navbar;
  }

  .sidebar {
    grid-area: sidebar;
    &.is-expanded + .routerview {
      padding-left: $sidebar-collapsed-width;
    }
  }

  .routerview {
    grid-area: routerview;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100vh;
    width: 100%;
    padding: $topbar-height 0 0 $sidebar-width;
    transition: padding .2s ease-in-out;
  }

  .footer {
    grid-area: footer;
  }
}
</style>



<!-- <template>
  <TopNavBar />
  <div class="flex">
    <SideBarPage v-if="currentUser"/>
    <router-view v-if="ready" />
  </div>
  <FooterBar />
</template>

<script>
import TopNavBar from './components/TopNavBar/TopNavBar.vue'
import FooterBar from '@/components/FooterBar/FooterBar.vue';
import SideBarPage from './views/SideBarPage.vue';


export default {
  components: {
    TopNavBar,
    FooterBar,
    SideBarPage
  },
  data() {
    return {
      goBackRoute: null,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    ready() {
      return this.$store.state.checkedForUserInLocalStorage
    },
    token() {
      return this.$store.getters["user/token"]
    },
  },
  watch: {
    async currentUser(current, old) {
      // não tem login
      if (current == null) {
        return this.$router.push("/entrar")
      }
      //Tem login, mas não esta no entrar
      // if (this.$route.name != 'entrar') {
      if (window.location.pathname != "/entrar") {
        return
      }
      // fez login com sucesso
      if (old == null) {
        if (this.goBackRoute) {
          this.$router.push(this.goBackRoute)
        } else {
          this.$router.push("/home")
        }
        this.$store.dispatch("getUser")
      }
    },
    ready() {
      if (this.ready && this.currentUser == null) {
        if (window.location.pathname != "/entrar") {
          this.goBackRoute = window.location.pathname + window.location.search
        }
        this.$router.push("/entrar")
      }
    },
  },
  mounted() {
    this.$store.dispatch("getUserFromLocalStorage")
  }
}
</script>

<style lang="scss">
@import './assets/utilities/_variables.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $black;
  background: $body-bg ;
}

a { 
  text-decoration: none; 
}

.flex {
  display: flex;
  align-items: center;
  padding-top: 40px;

}
</style> -->
