<template>
    <div class="classifiers-items-container">
        <BubbleContent v-if="exam" :title="'Classificar' + ' - ' + exam.name">
            <p class="classifiers-items-title">Item [Respostas]</p>
            <div v-if="itemsAreClassified">
                <p class="classifiers-items-list" v-for="item in filterItemsByAttribution" :key="item.id">
                    <router-link :to="{ name: routes[state], params: { itemId: item.id } }">
                        <strong>{{ item.name }} </strong>
                        <span> [{{ item.classifications_count[state] }}]</span>
                    </router-link>
                </p>
            </div>
        </BubbleContent>
    </div>
</template>
<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';


export default {
    props: {
        itemId: {
            default: null
        },
        id: {
            required: true,
        },
        state: { required: true },
    },
    components: {
        BubbleContent,
    },
    data() {
        return {
            fulfilled: true,
            routes: {
                0: "ClassifierExamAnswersContent",
                1: "ClassifiersExamSavedAnswersContent",
                2: "ClassifiersExamToBeReviewdAnswersContent",
                3: "ClassifiersExamToReviewAnswersContent",
            },
        }
    },
    methods: {
        itemsAreClassified() {
            if (this.items[this.state].classifications_count[this.state] == 0) {
                this.fulfilled == !this.fulfilled
            }
        },
    },
    computed: {
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        items() {
            return this.$store.state.examItems.all
        },
        filterItemsByAttribution() {
            const itemAttribution = this.items.filter((item) => item.classifications_number !== 0)
            return itemAttribution
        },
    },
    mounted() {
        this.$store.dispatch('exams/getAll')
        this.$store.dispatch('examItems/getAll', this.id)
        if (this.exam == null) {
            this.$store.dispatch('exams/getId', this.id)
        }

    },

}
</script>
<style lang="scss" scoped>
@import '../../../../../assets/utilities/_variables.scss';

.classifiers-items-container {
    margin: 20px;

    .classifiers-items-title {
        border-bottom: 2px solid $primary-color;
        margin: 0;
        padding: 1rem 0;

    }

    .classifiers-items-list {
        padding: 1rem 0;
        border-top: 1px solid gray;
        margin: 0;


        &:hover {
            background: rgba($primary-color, .1);
        }
    }
}
</style>