<template>
    <div class="body">
        <div class="title">
            <!-- <router-link :to="{name: 'ExamItems'}">
                <i>{{ icon }}</i>
                <IconComponent />
            </router-link> -->
            <h2>{{ title }}</h2>
        </div>
        <div class="content">
            <p>{{ content }}</p>
            <slot></slot>
            <form @submit.prevent.stop="$emit('submit', $event)">
                <slot name="form">
                </slot>
                <button v-for="button in buttons" :key="button.text" :disabled="button.disabled" :type="button.type"
                    @click.prevent.stop="button.click ? button.click() : $emit('submit', $event)">
                    <span class="button-text">{{ button.text }}</span>
                </button>
            </form>
        </div>
    </div>
</template>
<script>
// import IconComponent from '@/assets/images/IconComponent.vue'

export default {
    components: {
        // IconComponent,
    },
    props:
        ['icon', 'title', 'content', 'button', 'buttons', 'disabled'],


}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';


.body {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);

    .title {
        display: flex;
        align-items: center;
        padding-left: 15px;
        border-radius: 10px 10px 0 0;
        background: $primary-color;
        height: 40px;

        .material-icons {
            width: 20px;
            fill: $white;
            margin-top: 5px;
        }


        h2 {
            color: $white;
            margin: 0;
            font-size: 1.1rem;
            font-weight: 700;
            margin-left: 10px;
        }
    }

    .content {
        border-radius: 0 0 20px 20px;
        background: $white;
        height: 100%;
        padding: 0 20px;

        p {
            color: $primary-color;
            margin: 0;
        }

    }

    form {
        :deep input:not([type=checkbox], [type=radio]) {
            border-radius: 0;
            background-color: $white;
            border: none;
            border-bottom: 4px solid $secondary-color;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            outline: none;
            appearance: none;
            overflow: visible;
            font-family: sans-serif;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;


            &:focus {
                border-bottom: 4px solid $primary-color;
                transition: border-color .2s ease-out;

            }
        }

        button {
            width: 100%;
            color: $primary-color;
            background-color: rgba(255, 255, 255, 0);
            text-transform: uppercase;
            border-color: transparent;
            padding: 0.4em;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition-duration: 0.7s;
            line-height: 1;
            height: 2em;
            display: inline-block;
            border-top: 1px solid $primary-color;
            position: relative;

            &:disabled {
                pointer-events: none;

                .button-text {
                    color: $light-border-color;
                }
            }

            &:before {
                content: "";
                background-image: linear-gradient(90deg, rgba($primary-color, 0), rgba($primary-color, 1), rgba($primary-color, 0));
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                transition: opacity .2s ease;
            }


            &:hover {
                transition: background-color .7s;

                &::before {
                    opacity: .25;
                }
            }
        }
    }
}
</style>