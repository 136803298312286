var listeners = {}
function periodicUpdate(axios, exam, commit) {
  clearTimeout(listeners[exam.id])
  listeners[exam.id] = setTimeout(async () => {
    const results = await axios.get(
      `${process.env.VUE_APP_API_HOST}/exams/${exam.id}`
    );
    let examUpdated = results.data.data
    commit("exam", examUpdated);
    if( examUpdated.task_pinged_at != null ) {
      periodicUpdate(axios, examUpdated, commit)
    }
  }, 10 * 1000)
}

const exams = {
  namespaced: true,
  state: () => ({
    all: [],
    exam: null,
    examExportFiles: [],
    examExportFilesDownload: []
  }),
  getters: {
    getId: (state) => (id) => {
      return state.all.find((exam) => exam.id === parseInt(id));
    },
    last(state) {
      return state.all.reduce( (pr, curr, index) => {
        if( index == 0 ) return curr
        if( pr.id > curr.id ) return pr
        return curr
      }, null )
    },
    exportFiles: (state) => (id) => {
      const examFiles = state.examExportFiles.find((files) => parseInt(files.id) === parseInt(id));
      if( examFiles ) {
        return examFiles.files
      }
      return []
    }
  },
  mutations: {
    add(state, newExam) {
      state.all.push(newExam);
    },
    all(state, exams) {
      state.all = exams;
    },
    clear(state) {
      state.all = []
      state.exam = null
    },
    delete(state, exam) {
      const index = state.all.findIndex(({ id }) => exam.id === id);
      state.all.splice(index, 1);
    },
    exam(state, exam) {
      const index = state.all.findIndex(({ id }) => exam.id === id);
      if (index < 0) {
        state.all.push(exam);
      } else {
        state.all.splice(index, 1, exam);
      }
    },
    examExportFiles(state, {id, files}) {
      const index = state.examExportFiles.findIndex(files => files.id === id);
      if (index < 0) {
        state.examExportFiles.push({id, files});
      } else {
        state.examExportFiles.splice(index, 1, {id, files});
      }
    },
    examExportFilesDownload(state, {id, downloading}) {
      const index = state.examExportFilesDownload.findIndex(fileId => fileId === id);
      if (index < 0 && downloading) {
        state.examExportFilesDownload.push(id);
      } else if( index >= 0 && !downloading ) {
        state.examExportFilesDownload.splice(index, 1);
      }
    },
  },
  actions: {
    // CREATE ACTION
    async create({ rootState, commit }, newExam) {
      try {
        const results = await rootState.axios.post(
          `${process.env.VUE_APP_API_HOST}/exams`,
          newExam
        );
        let exam = results.data.data
        exam.created = true
        commit("add", exam);
      } catch (error) {
        console.log(error);
      }
    },
    // DELETE ACTION
    async delete({ rootState, commit }, id) {
      try {
        const results = await rootState.axios.delete(
          `${process.env.VUE_APP_API_HOST}/exams/${id}`
        );
        commit("delete", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async download({ state, rootState, commit }, {id, file}) {
      try {
        let fileDownloading = state.examExportFilesDownload.includes(file.id)
        if( fileDownloading ) {
          return
        }
        commit("examExportFilesDownload", {id: file.id, downloading: true})
        const res = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/${id}/export/${file.id}`,
          {responseType: 'blob'}
        );
        commit("examExportFilesDownload", {id: file.id, downloading: false})
        let blob = new Blob([res.data], { type: 'application/octet-stream' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file.filename
        link.click()
      } catch(error) {
        console.log("ERROR:" + error)
      }
    },
    // EDIT ACTION
    async edit({ rootState, commit }, { id, exam }) {
      const results = await rootState.axios.put(
        `${process.env.VUE_APP_API_HOST}/exams/${id}`,
        exam
      );
      commit("exam", results.data.data);
    },
    // GET ALL EXAMS ACTION
    async getAll({ rootState, commit }) {
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams`
        );
        commit("all", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    // GET EXAM BY ID ACTION
    async getId({ rootState, commit }, id) {
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/${id}`
        );
        let exam = results.data.data
        commit("exam", exam);
        if( exam.task_pinged_at != null ) {
          periodicUpdate(rootState.axios, exam, commit)
        }
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async getExportFiles({rootState, commit}, id) {
      try {
        const res = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/${id}/export`
        );
        commit("examExportFiles", {id, files: res.data.data});
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    // EXPORT EXAM ACTION
    async export({rootState}, id) {
      try {
        const res = await rootState.axios.post(
          `${process.env.VUE_APP_API_HOST}/exams/${id}/export`,
        );
        console.log(res.data.data)
      } catch(error) {
        console.log("ERROR:" + error)
      }
    },
    // IMPORT EXAM ANSWERS ACTION
    async import({rootState, commit}, {id, file, attachment}) {
      try {
        let formData = new FormData()
        formData.append("file", file)
        formData.append("attachment", attachment)
        const res = await rootState.axios.post(
          `${process.env.VUE_APP_API_HOST}/exams/${id}/import`,
          formData,
          {headers: {
            'Content-Type': 'multipart/form-data'
          }}
        );
        let exam = res.data.data
        commit('exam', exam)
        if( exam.task_pinged_at != null ) {
          periodicUpdate(rootState.axios, exam, commit)
        }
      } catch(error) {
        console.log("ERROR:" + error)
      }
    },
    // RESET EXAM STATE ACTION
    async reset({rootState, commit}, id) {
      const res = await rootState.axios.post(
        `${process.env.VUE_APP_API_HOST}/exams/${id}/reset`,
      );
      commit("exam", res.data.data);
    },
  },
};

export default exams;
