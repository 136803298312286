<template>
    <div class="home">
        <TopContainerNav title="SCOI" class="home_topbar"/>
        <div class="home_content">
            <router-link to="/exams">
                <BubbleContent title="Provas" />
            </router-link>
            <router-link to="/users" v-if="isAdmin">
                <BubbleContent title="Utilizadores" content="1 Utilizadores Registados"/>
            </router-link>
            <!-- <router-link to="/schools" v-if="isAdmin">
                <BubbleContent title="Escolas" />
            </router-link>
            <router-link to="/students" v-if="isAdmin">
                <BubbleContent title="Estudantes"/>
            </router-link> -->
        </div>
    </div>
</template>
<script>
import TopContainerNav from '@/components/SCOI/TopContainerNav/TopContainerNav.vue';
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';

export default {
    components: {
        TopContainerNav,
        BubbleContent,
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser
        },
        isAdmin() {
            return this.currentUser.role === 1
        },
    }

}
</script>
<style lang="scss" scoped>
.home {
    position: relative;
    flex-grow: 1;
    align-self: flex-start;
    flex-direction: column;
    
    &_content {
        gap: 1rem;
        padding: 1rem;
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>