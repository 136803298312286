<template>
    <ContainerCard :columns=columns>
        <div class="banner">
            <strong>Classificar</strong>
        </div>
        <p v-for="exam in exams" :key="exam.id" class="list">
            <router-link :to="{ name: 'ClassifiersExamItems', params: { id: exam.id } }">
                <div class="exams">
                    <p>{{ exam.name }}</p>
                    <p>{{ exam.starts_at }}</p>
                    <p>{{ exam.ends_at }}</p>
                    <p>{{ exam.progress }}</p>
                </div>
            </router-link>
        </p>
    </ContainerCard>
</template>
<script>
import ContainerCard from '@/components/SCOI/ContainerCard/ContainerCard.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        ContainerCard
    },
    data() {
        return {
            columns: [
                { text: 'Nome' },
                { text: 'Início a' },
                { text: 'Fim a' },
                { text: 'Conclusão' },
            ],
        }
    },
    computed: {
        exams() {
            return this.$store.state.exams.all;
        }
    },
    mounted() {
        this.$store.dispatch('exams/getAll')


    },

}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

.banner {
    background: $primary-color;
    color: $white;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.list {
    margin: 0;
    padding-left: 1rem;
    border-top: 1px solid gray;

    &:hover {
        background: rgba($primary-color, 0.1);

    }

    .exams {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(5, 1fr);

    }
}
</style>