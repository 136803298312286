<template>
  <div class="foo-container">
    <span>© IAVE 2018. Todos os acessos não autorizados a esta página são estritamente proibidos.</span>
    <span>Desenvolvido por BlatStudio</span>
  </div>
</template>

<script>

export default {
  data() {
    return {

    };
  }

}

</script>

<style lang="scss" scoped>
@import '../../assets/utilities/_variables.scss';

.foo {
  &-container {
    background: $footer-bg-color;
    font-size: $footer-font-size;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid $dark-border-color;
    z-index: 1050;

    span {
      padding: 0 4px;
    }
  }
}
</style>