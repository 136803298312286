<template>
    <form class="content-code" :class="{name: name != null}">
        <InputContent class="code-input" label="Código" v-model="value" :itemId="itemId" />
        <button class="code-button" :disabled="!canSubmit"
            @click.prevent="$store.dispatch('itemCodes/create', { newCode: newItemCode, item_id: itemId })">
            <span class="button-text">Adicionar Código</span>
        </button>
    </form>
</template>
<script>
import InputContent from '@/components/SCOI/Inputs/InputContent.vue';

export default {
    props: {
        itemId: {
            required: true,
        },
        name: {default: null},
    },
    components: {
        InputContent,
    },
    data() {
        return {
            value: '',
        };
    },
    computed: {
        canSubmit() {
            return this.value != '';
        },
        item() {
            return this.$store.getters['examItems/getId'](this.itemId)
        },
        code() {
            return this.$store.getters['itemCodes/getId'](this.itemId)
        },
        newItemCode() {
            return {
                value: this.value,
                name: this.name,
            }
        },
    },
    methods: {
    },

}
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.content-code {
    display: grid;
    grid-template-areas: "code-input code-button";
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
    border: 1px solid;
    border-radius: 4px;
    padding: .5rem;

    &.name {
        grid-template-areas: "code-input"
                             "code-button";
        grid-template-columns: 1fr;
        gap: 0;
    }

    .code-input {
        grid-area: code-input;
    }

    .code-button {
        grid-area: code-button;
        text-transform: uppercase;
        // margin-top: 61px;
        border: none;
        border: 1px solid $primary-color;
        border-radius: 4px;
        background-color: $white;
        position: relative;
        color: $primary-color;

        &:disabled {
            pointer-events: none;
            .button-text {
                color: $light-border-color;
            }
        }

        .button-text {
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:before {
                content: "";
                background-image: linear-gradient(90deg, rgba($primary-color, 0), rgba($primary-color, 1), rgba($primary-color, 0));
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                transition: opacity .2s ease;
            }


            &:hover {
                transition: background-color .7s;
                color: $dark-border-color;

                &::before {
                    opacity: .1;
                }
            }

        }
    }

    // :deep input {
    input {
        border-radius: 0;
        background-color: $white;
        border: none;
        border-bottom: 4px solid $secondary-color;
        width: 100%;
        height: 3em;
        padding: 0 1em;
        outline: none;
        appearance: none;
        overflow: visible;
        line-height: 1.15;
        margin: 0;


        &:focus {
            border-bottom: 4px solid $primary-color;
            transition: border-color .2s ease-out;
        }
    }
}
</style>