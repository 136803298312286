const itemClassification = {
  namespaced: true,
  state: () => ({
    all: [],
    messages: [],
    fetching: [],
  }),
  getters: {
    getId: (state) => (id) => {
      return state.all.filter((classification) => classification.id === parseInt(id))
    },
    getItem: (state) => (itemId, classificationState = 0) => {
      return state.all.filter( (classification) => classification.exam_item_id == itemId && classification.state == classificationState)
    },
    fetching: (state) => (pageData) => {
      return state.fetching.findIndex( ({itemId, page, state}) => {
        return itemId == pageData.itemId &&
               page == pageData.page &&
               state == pageData.state 
      }) > -1
    }
  },
  mutations: {
    addMsg(state,messages){
      state.messages.push(messages)
    },
    addMany(state, classifications) {
      state.all.push(...classifications)
    },
    add(state, classification) {
      let foundIndex = state.all.findIndex( ({id}) => id == classification.id )
      if( foundIndex < 0 ) {
        state.all.push(classification)
        return
      }
      state.all.splice(foundIndex, 1, classification)
    },
    all(state, classifications) {
      state.all = classifications;
    },
    clear(state) {
      state.all = []
    },
    fetched(state, pageData) {
      let index = state.fetching.findIndex( ({itemId, page, state}) => {
        return itemId == pageData.itemId &&
               page == pageData.page &&
               state == pageData.state 
      })
      state.fetching.splice(index, 1)
    },
    fetching(state, pageData) {
      state.fetching.push(pageData)
    },
  },
  actions: {
    async classify( {rootState, commit}, {classification, code, state} ) {
      let classificationId = classification.id
      let params = {
        state,
        itemCodes: code.map(({id}) => id ) 
      }
      try {
        const results = await rootState.axios.put(
          `${process.env.VUE_APP_API_HOST}/exams/items/classifications/${classificationId}`,
          params
        );
        commit('add', results.data.data)
      } catch(error) {
        console.log("ERROR " + error);
      }
    },
    async getOne({ rootState, commit, getters }, { exam, examItem, page, state = 0 }) {
      if( page == null ) page = 1
      let pageData = {examId: examItem, page, state }
      if(getters.fetching(pageData)) {
        return
      }
      let classifications = getters['getItem'](examItem, state)
      if( classifications.length >= page ) {
        return
      }
      commit('fetching', pageData)
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/exams/${exam}/items/${examItem}/classifications`,
          {params: {
            page, state
          }}
        );
        commit('add', results.data.data)
      } catch (error) {
        console.log("ERROR " + error);
      }
      commit('fetched', pageData)

    },
    storeMessage({commit}, message) {
      commit('addMsg', message)
    },
  },
};

export default itemClassification;
