<template>
    <div class="lcf-background imageCover">
        <div class="lcf-container ">
            <div class="container-logo">
                <img src="../../assets/images/logo_web.png">
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>


export default {
    components: {
    },
    data() {
        return {
        };
    },

}
</script>

<style lang="scss" scoped>
@import '../../assets/utilities/_variables.scss';

.lcf {
    &-background {
        max-height: 100vh;
        height: 100vh;
        width: 100vw;
        margin: 0;
        padding-left: 0;

        &.imageCover {
            background-image: url('../../assets/images/bg.jpg');
            background-size: cover;
            background-position: center;
        }
    }

    &-container {
        margin: auto;
        width: 90%;
        max-width: 500px;
        background-color: rgba(255, 255, 255, 0.65);
        border-radius: 10px;
        color: $white;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;

        .container-logo {
            margin: 0;
            padding: 25px;
            background: rgba(255, 255, 255, 0.65);
            text-align: center;
            width: 100%;
        }
    }


}
</style>