const classifiers = {
  namespaced: true,
  state: () => ({
    all: [],
    loadingExams: [],
  }),
  getters: {
    exam: (state) => (id) => {
      return state.all.filter(({exam_id}) => exam_id == id)
    },
    loading: (state) => (id) => {
      return state.loadingExams.includes(id)
    }
  },
  mutations: {
    add(state, classifier) {
      let index = state.all.findIndex( ({id}) => id == classifier.id )
      if( index > -1 ) {
        state.all.splice(index,1,classifier)
      } else {
        state.all.push(classifier)
      }
    },
    addMany(state, classifiers) {
      for( let classifier of classifiers ) {
        let index = state.all.findIndex( ({id}) => id == classifier.id )
        if( index > -1 ) {
          state.all.splice(index,1,classifier)
        } else {
          state.all.push(classifier)
        }
      }
    },
    clear(state) {
      state.all = []
      state.loadExams = []
    },
    loadExam(state, examId) {
      state.loadingExams.push(examId)
    },
    loadedExam(state, examId) {
      state.loadingExams = state.loadingExams.filter( id => id != examId )
    },
  },
  actions: {
    async import( {rootState}, {examId, file} ) {
      let formData = new FormData()
      formData.append("file", file)
      await rootState.axios.post(
        `${process.env.VUE_APP_API_HOST}/exams/${examId}/classifiers`,
        formData,
        {headers: {
          'Content-Type': 'multipart/form-data'
        }}
      )
    },
    async upgrade( {rootState, commit}, classifierId ) { {
      let res = await rootState.axios.post(
        `${process.env.VUE_APP_API_HOST}/exams/classifiers/${classifierId}`,
        {headers: {
          'Content-Type': 'multipart/form-data'
        }}
      )
      commit('add', res.data.data)
    }
    },
    async getAll( {state, rootState, commit}, {examId, page = null} ) {
      if( state.loadingExams.includes(examId) ) {
        return
      }
      commit('loadExam', examId)
      let res = null
      if( page == null ) {
        res = await rootState.axios.get(`${process.env.VUE_APP_API_HOST}/exams/${examId}/classifiers`)
      } else {
        res = await rootState.axios.get(`${process.env.VUE_APP_API_HOST}/exams/${examId}/classifiers`,
        {params: {page}})
      }
      commit('addMany', res.data.data)
      commit('loadedExam', examId)
    },
  },
};

export default classifiers;
