<template>
    <BubbleContent :title="exam.name + ' ' + '(A Classificar)'">
        <template #form>
            <form>
                <p v-for="line in list" :key="line.name">
                    <strong>{{ line.name }}: </strong>
                    <span>{{ line.answer }}</span>
                </p>
                <p>
                    <strong>Submissões: </strong>
                    <span v-if="submissions" v-text="submissions"></span>
                    <a href="#" @click.prevent="checkSubmissions" class="checkLink">Verificar</a>
                </p>
                <table>
                    <thead>
                        <tr class="info-classification-columns">
                            <th>Classificador</th>
                            <th v-for="item in items" :key="item.id" v-text="item.name">
                            </th>
                            <th>Classificadas|Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="info-classification-list" v-for="classifier in classifiers" :key="classifier.id">
                            <td v-text="classifier.nr_classificador">
                            </td>
                            <td v-for="item in items" :key="item.id">
                                <template v-if="classifier.classified_items[item.id]">
                                    <span v-text="classifier.classified_items[item.id].classified"></span> |
                                    <span v-text="classifier.classified_items[item.id].total"></span>
                                </template>
                            </td>
                            <td>
                                <span v-text="classifier.classified"></span> |
                                <span v-text="classifier.classifications_total"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr class="info-classification-columns">
                            <th>Respostas...</th>
                            <th>Total</th>
                            <th v-for="item in items" :key="item.id" v-text="item.name">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="classification in classifications" :key="classification.answer">
                            <td>{{ classification.answer }}</td>
                        </tr>
                    </tbody>
                </table>
                <span>
                    <router-link :to="{ name: 'ExamInfo' }" class="double-classification-link">
                        Atualizar informação sobre dupla classificação - (EM REVISÃO...)
                    </router-link>
                </span>
            </form>
        </template>
    </BubbleContent>
</template>
<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';
export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        BubbleContent,
    },
    data() {
        return {
            submissions: null,
            classifications: [
                { answer: 'Duplamente classificadas' },
                { answer: 'Triplamente classificadas' },
                { answer: 'Classificadas após conflito' }, 
            ]
        }
    },
    methods: {
        async checkSubmissions() {
            const res = await this.$store.state.axios.get(
                `${process.env.VUE_APP_API_HOST}/exams/${this.id}/submissions/count`
            );
            this.submissions = res.data.data
        },
        getClassifiers() {
            this.$store.dispatch('classifiers/getAll', { examId: this.id })
            // this.$store.dispatch('classifiers/getAll', {examId: this.id, page: 1})
        }
    },
    computed: {
        // nextPage() {
        //     return (this.classifiers.length / 1) + 1;
        // },
        classifiers() {
            return this.$store.getters['classifiers/exam'](this.id)
        },
        classifiedTotal() {
            const arrayClassification = this.classifiers.map((classifier) => classifier.classified)
            const initialValue = 0
            const sumClassification = arrayClassification.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue)
            return sumClassification
        },
        doubleClassification() {
            const arrayClassification = this.classifiers.map((classifier) => classifier.statistic_double_classifications)
            const initialValue = 0
            const sumClassification = arrayClassification.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue)
            return sumClassification
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        items() {
            return this.$store.state.examItems.all
        },
        list() {
            return [
                { name: 'Nome', answer: this.exam.name },
                { name: 'Tabela', answer: this.exam.table },
                { name: 'Tipo', answer: this.exam.kind },
                { name: 'Progresso', answer: this.exam.progress },
                { name: 'Respostas', answer: 'TO CHECK' },
                { name: 'Classificadas', answer: this.classifiedTotal },
            ]
        },
    },
    watch: {
    },
    mounted() {
        this.$store.dispatch('examItems/getAll', this.id)

        if (this.exam == null) {
            this.$store.dispatch('exams/getId', this.id)
        }
        this.getClassifiers()
        // console.log(this.classifiers)
    },
}

</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

.checkLink {
    color: $primary-color;
    margin: 0 0 0 .7em;
}

.info-classification-list {
    border-top: 1px solid rgba($black, .5);

}

table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    td,
    th {
        padding: .5em 0;
    }

    .info-classification-columns {
        border-bottom: 2px solid $primary-color;

    }
}




.double-classification-link {
    text-decoration: none;
    color: $primary-color;
    cursor: pointer;
}</style>