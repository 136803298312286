<template>
    <div class="info-classifiers-title">
        <router-link :to="{ name: 'ExamInfo' }" >
            <IconComponent class="material-icons" icon="chevron-left" />
            <span>Classificadores</span>
        </router-link>
    </div>
    <div class="info-classifiers-columns">
        <p v-for="column in columns" :key="column.id">
            <strong>{{ column.text }}</strong>
        </p>
    </div>
    <div class="info-classifiers-list" v-for="item in items" :key="item.id">
        <div class="list-item-container">
            <p>{{ item.name }}</p>
            <strong>(VALUE %)</strong>
        </div>
        <p>CHECK VALUE</p>
        <p>CHECK VALUE</p>
    </div>
</template>
<script>
import IconComponent from '@/assets/images/IconComponent.vue'

export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        IconComponent,
    },
    data() {
        return {
            columns: [
                { text: 'Item' },
                { text: 'Classificadas' },
                { text: 'Total' },
            ],

        }
    },
    computed: {
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        items() {
            return this.$store.state.examItems.all
        },
    },
    mounted() {
        if (this.exam == null) {
            this.$store.dispatch('exams/getId', this.id)
        }
    }

}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

.info-classifiers-title{
    color: $primary-color;
    border-bottom: 1px solid $primary-color;
    text-transform: uppercase;
    .material-icons {
        width: 10px;
        fill: rgba($primary-color, 0.8);
    }
}

.info-classifiers-columns {
    border-bottom: 2px solid $primary-color;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.info-classifiers-list {
    border-top: 1px solid gray;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .list-item-container{
        margin: 0;
        display: flex;
        background-color: rgb($secondary-color, 0.2);
        justify-content: space-between;
        padding: 0 1em;
        height: 100%;
        align-items: center;
        cursor: pointer;
    }

}
</style>