import ExamsPage from "../views/ExamsPage.vue";
import ExamsList from "../components/SCOI/TabExams/ExamsList.vue";
import ExamCreate from "../components/SCOI/TabExams/ExamCreate.vue";
import ClassifiersExamsList from "../components/SCOI/ClassifiersPlatform/ClassifiersExams/ClassifiersExamsList.vue";
import ClassifiersExamItems from "../components/SCOI/ClassifiersPlatform/ClassifiersExams/ClassifiersExam/ClassifiersExamItems.vue";
// import ClassifiersExamSelectedToReview from "../components/SCOI/ClassifiersPlatform/ClassifiersExams/ClassifiersExam/ClassifiersExamSelectedToReview.vue";
// import ClassifiersExamSaved from "../components/SCOI/ClassifiersPlatform/ClassifiersExams/ClassifiersExam/ClassifiersExamSaved.vue";
// import ClassifiersExamToReview from "../components/SCOI/ClassifiersPlatform/ClassifiersExams/ClassifiersExam/ClassifiersExamToReview.vue";
import ClassifierExamAnswersContent from "../components/SCOI/ClassifiersPlatform/ClassifiersExams/ClassifiersExam/ClassifierExamAnswersContent/ClassifierExamAnswersContent.vue";

const exams = [
  {
    path: "/exams",
    component: ExamsPage,
    props: true,
    children: [
      {
        path: "",
        name: "ExamsList",
        component: ExamsList,
        props: true,
        meta: { adminLogin: true, redirect: "ClassifiersExamsList" },
      },
      {
        path: "lista",
        name: "ClassifiersExamsList",
        component: ClassifiersExamsList,
        props: true,
      },
      {
        path: ":id/classificacoes",
        name: "ClassifiersExamItems",
        component: ClassifiersExamItems,
        props: route => ({
          id: parseInt(route.params.id),
          state: 0,
        })
      },
      {
        path: ":id/classificacoes/:itemId",
        name: "ClassifierExamAnswersContent",
        component: ClassifierExamAnswersContent,
        props: route => ({
          id: parseInt(route.params.id),
          itemId: parseInt(route.params.itemId),
          state: 0,
        })
      },
      {
        path: ":id/classificacoes/gravadas",
        name: "ClassifiersExamSaved",
        component: ClassifiersExamItems,
        props: route => ({
          id: parseInt(route.params.id),
          state: 1,
        })
      },
      {
        path: ":id/classificacoes/gravadas/:itemId",
        name: "ClassifiersExamSavedAnswersContent",
        component: ClassifierExamAnswersContent,
        props: route => ({
          id: parseInt(route.params.id),
          itemId: parseInt(route.params.itemId),
          state: 1,
        })
      },
      {
        path: ":id/classificacoes/paraRevisao",
        name: "ClassifiersExamToBeReviewd",
        component: ClassifiersExamItems,
        props: route => ({
          id: parseInt(route.params.id),
          state: 2,
        })
      },
      {
        path: ":id/classificacoes/paraRevisao/:itemId",
        name: "ClassifiersExamToBeReviewdAnswersContent",
        component: ClassifierExamAnswersContent,
        props: route => ({
          id: parseInt(route.params.id),
          itemId: parseInt(route.params.itemId),
          state: 2,
        })
      },
      {
        path: ":id/classificacoes/revisao",
        name: "ClassifiersExamToReview",
        component: ClassifiersExamItems,
        props: route => ({
          id: parseInt(route.params.id),
          state: 3,
        })
      },
      {
        path: ":id/classificacoes/revisao/:itemId",
        name: "ClassifiersExamToReviewAnswersContent",
        component: ClassifierExamAnswersContent,
        props: route => ({
          id: parseInt(route.params.id),
          itemId: parseInt(route.params.itemId),
          state: 3,
        })
      },
      {
        path: "create",
        name: "ExamCreate",
        component: ExamCreate,
      },
    ],
  },
];

export default exams;
