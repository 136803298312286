<template>
    <BubbleContent :title="group.name" :buttons="button" @submit="edit">
        <template #form>
            <p>
                <strong>Supervisor: </strong>
                <span> {{ supervisor.name }}</span>
            </p>
            <h3>Classificadores</h3>
            <div class="group-items">
                <p class="items" v-for="column of columns" :key="column.text">{{ column.text }}</p>
            </div>
            <div v-for="classifier in classifiers" :key="classifier.id" class="list-classifiers">
                <div class="classifiers">
                    <p>{{ classifier.name }}</p>
                    <p>{{ classifier.supervisor_classifications }}</p>
                    <span @click.prevent="removeClassifier(classifier)">Remover</span>
                </div>
            </div>
            <div>
                <p>Procure Classificador</p>
                <input type="text" v-model="searchClassifier">
                <div class="usersList">
                    <p v-for="classifier in groupClassifiers" :key="classifier.id"
                        @click.prevent="addClassifier(classifier)">
                        <span v-if="classifier.name">
                            <strong>Nome: </strong> {{ classifier.name }} |
                        </span>
                        <span v-if="classifier.email">
                            <strong>Email: </strong> {{ classifier.email }} |
                        </span>
                        <span v-if="classifier.nr_classificador">
                            <strong>Nr Classificador: </strong> {{ classifier.nr_classificador }} |
                        </span>
                    </p>
                </div>
                <!-- <div>
                    <p v-for="classifier in groupClassifiers" :key="classifier.user_id"
                        @click.prevent="addClassifier(classifier)">
                        <span>
                            <strong>{{ classifier.name }} - {{ classifier.email }}</strong>
                            - Toque para seleccionar
                        </span>
                    </p>
                </div> -->
            </div>
            <br>
        </template>
    </BubbleContent>
</template>
<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';

export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        BubbleContent,
    },
    data() {
        return {
            button: [
                { text: 'Actualizar Grupo', type: 'submit' },
                { text: 'Eliminar Grupo', type: 'submit', click: this.delete },
            ],
            columns: [
                { text: 'Nome' },
                { text: 'Classificações' },
            ],
            searchClassifier: '',
            classifier: null,
            newClassifiers: [],
            removeClassifiers: [],
        };
    },
    methods: {
        edit() {
            this.$store.dispatch('examGroups/edit', {
                group: this.groupData,
                examId: this.group.exam_id,
                examClass: this.id,
            })
        },
        delete() {
            this.$store.dispatch('examGroups/delete', { exam: this.group.exam_id, examClass: this.id })
        },
        addClassifier(user) {
            this.$store.dispatch('examGroups/addClassifier', { examClass: this.id, classifierId: user.id })
            // const removeClassifiersIndex = this.removeClassifiers.findIndex((id) => id == user.id)
            // if (removeClassifiersIndex > -1) {
            //     this.removeClassifiers.splice(removeClassifiersIndex, 1)
            // } else if (!this.newClassifiers.includes(user.id)) {
            //     this.newClassifiers.push(user.id)
            // }
        },
        removeClassifier(user) {
            const classifierId = this.group.classifierIds[user.id]
            this.$store.dispatch('examGroups/remClassifier', { classifierId })
            // const newClassifiersIndex = this.newClassifiers.findIndex((id) => id == user.id)
            // if (newClassifiersIndex > -1) {
            //     this.newClassifiers.splice(newClassifiersIndex, 1)
            // } else if (!this.removeClassifiers.includes(user.id)) {
            //     this.removeClassifiers.push(user.id)
            // }
        },
    },
    computed: {
        classifiers() {
            if (this.group) {
                return (this.$store.getters['users/withId']([...this.group.users, ...this.newClassifiers])).filter(({ id }) => !this.removeClassifiers.includes(id))
            }
            return []
        },
        group() {
            return this.$store.getters['examGroups/getId'](this.id)
        },
        users() {
            return this.$store.state.users.all
        },
        groupClassifiers() {
            if (this.searchClassifier) {
                return this.users.filter((supervisor) => {
                    let search = this.searchClassifier.toString().toLocaleLowerCase()
                    let includesName = false
                    if( supervisor.name ) includesName = supervisor.name.toLowerCase().includes(search)
                    let includesNr = false
                    if( supervisor.nr_classificador) includesNr = supervisor.nr_classificador.toLowerCase().includes(search)
                    return includesName || includesNr
                }).slice(0, 5)
            } else {
                return []
            }
        },
        groupData() {
            if (this.supervisor == null || this.group == null) {
                return null
            }
            return {
                name: this.group.name,
                user_id: this.supervisor.id,
                classifierIds: this.classifiers.map( ({id}) => id ),
            }
        },
        supervisor() {
            if (this.group) {
                return this.$store.getters['users/getId'](this.group.user_id)
            }
            return null
        },
    },
    watch: {
        group: {
            immediate: true,
            handler() {
                if (!this.group) {
                    return
                }
                this.$store.dispatch('users/getId', this.group.user_id)
                for (let userId of this.group.users) {
                    this.$store.dispatch('users/getId', userId)
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch("users/getAll");
    },
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

.group-items {
    padding: 1rem;
    border-bottom: 2px solid $primary-color;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .items {
        margin: 0;
        font-weight: bold;
    }
}

.list-classifiers {
    margin: 0;
    padding-left: 1rem;
    border-bottom: 1px solid gray;

    &:hover {
        background: rgba($primary-color, 0.1);

    }

    .classifiers {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(3, 1fr);

    }
}
</style>