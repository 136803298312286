<template>
    <div class="content-exams-groups">
        <ExamGroupsCreate :id="id"/>
        <ExamGroupList :id="id"/>
        
    </div>
</template>
<script>
import ExamGroupsCreate from './ExamGroupsCreate.vue';
import ExamGroupList from './ExamGroupList.vue';

export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        ExamGroupsCreate,
        ExamGroupList,
    },
    mounted() {
        this.$store.dispatch('examGroups/getAll', this.id)
    },
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

.content-exams-groups {
    margin: 20px;

}
    
</style>