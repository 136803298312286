import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../views/LoginPage.vue";
import ResetPassPage from "../views/ResetPassPage.vue";
import HomePage from "../views/HomePage.vue";
import users from "./users";
import schools from "./schools";
import exams from "./exams";
import examInfo from "./examInfo";

const routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/entrar",
    name: "entrar",
    component: LoginPage,
    props: route => ({
      token: route.query.token
    })
  },
  {
    path: "/senha-reset",
    name: "senha-reset",
    component: ResetPassPage,
  },
  {
    path: "/home",
    name: "home",
    component: HomePage,
  },
  ...examInfo,
  ...users,
  ...schools,
  ...exams,
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

import store from "../store"

router.beforeEach((to) => {
  if( to.query.token && store.state.currentUser != null ) {
    store.dispatch('logout')
    return {name: 'entrar', query: to.query}
  }

  if( store.state.currentUser == null && to.path != '/entrar' ) {
    const goBackTo = {params: to.params, path: to.path}
    store.commit('setAfterLoginRoute', goBackTo)
    return {name: 'entrar', query: to.query}
  }
  if( store.state.currentUser == null ) return true
  const isAdmin = store.state.currentUser.role == 1
  if ( to.meta.adminLogin && !isAdmin ) {
    return { name: to.meta.redirect }
  }
})

export default router;
