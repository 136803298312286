const schools = {
  namespaced: true,
  state: () => ({
    all: [],
    school: null,
  }),
  getters: {
    getId: (state) => (id) => {
      return state.all.find((school) => school.id === parseInt(id));
    },
  },
  mutations: {
    add(state, newSchool) {
      state.all.push(newSchool);
    },
    all(state, schools) {
      state.all = schools;
    },
    delete(state, school) {
      const index = state.all.findIndex(({ id }) => school.id === id);
      state.all.splice(index, 1);
    },
    user(state, school) {
      const index = state.all.findIndex(({ id }) => school.id === id);
      if (index < 0) {
        state.all.push(school);
      } else {
        state.all.splice(index, 1, school);
      }
    },
  },
  actions: {
    async create({ rootState, commit }, newSchool) {
      try {
        const results = await rootState.axios.post(
          `${process.env.VUE_APP_API_HOST}/schools`,
          newSchool
        );

        commit("add", results.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async delete({ rootState, commit }, id) {
      try {
        const results = await rootState.axios.delete(
          `${process.env.VUE_APP_API_HOST}/schools/${id}`
        );
        commit("delete", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async edit({ rootState, commit }, { id, school }) {
      try {
        const results = await rootState.axios.put(
          `${process.env.VUE_APP_API_HOST}/users/${id}`,
          school
        );
        commit("user", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async getAll({ rootState, commit }) {
      try {
        const results = await rootState.axios.get(
          `${process.env.VUE_APP_API_HOST}/schools`
        );
        commit("all", results.data.data);
      } catch (error) {
        console.log("ERROR: " + error);
      }
    },
    async getId({ getters, dispatch }, id) {
      if (getters.getId(id) == null) {
        dispatch("fetchId", id);
      }
    },
  },
};

export default schools;
