<template>
    <ExamGroupCard v-for="group in groups"
    :key="group.id"
    :id="group.id"
    :name="group.name"
    :supervisor_classifications="group.supervisor_classifications"
    />
</template>
<script>
import ExamGroupCard from './ExamGroupCard.vue';

export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        ExamGroupCard,
    },
    computed: {
        groups() {
            return this.$store.getters['examGroups/exam'](this.id)
        }
    },
    data() {
        return {
        };
    },
    
}
</script>
<style lang="scss" scoped>
    
</style>