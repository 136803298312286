<template>
    <BubbleContent :buttons=buttons title="Criar Grupo"
        @submit.prevent="$store.dispatch('examGroups/create', { newGroup: newGroupData, exam: id })">
        <template #form>
            <InputContent label="Nome do Grupo" v-model="name" />
            <div>
                <p>Supervisor Seleccionado</p>
                <div>
                    <div v-if="user">
                        <p>
                            <strong>{{ user.email || user.nr_classificador || user.name }}</strong>
                            -
                            <span @click.prevent="removeSupervisor(user)"> Remover</span></p>
                    </div>
                    <div v-for="user in usersList" :key="user.id">
                        <p>{{ user }} - <span @click.prevent="removeSupervisor(user)"> Remover</span></p>
                    </div>
                </div>
                <p>Procure Supervisor</p>
                <input type="text" v-model="searchSupervisor">
                <div class="usersList">
                    <p v-for="supervisor in groupSupervisor" :key="supervisor.id"
                        @click.prevent="addSupervisor(supervisor)">
                        <span v-if="supervisor.name">
                            <strong>Nome: </strong> {{ supervisor.name }} |
                        </span>
                        <span v-if="supervisor.email">
                            <strong>Email: </strong> {{ supervisor.email }} |
                        </span>
                        <span v-if="supervisor.nr_classificador">
                            <strong>Nr Classificador: </strong> {{ supervisor.nr_classificador }} |
                        </span>
                    </p>
                </div>
                <br>
            </div>
        </template>
    </BubbleContent>
</template>
<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';
import InputContent from '../../Inputs/InputContent.vue';

export default {
    components: {
        BubbleContent,
        InputContent,
    },
    props: {
        id: {
            required: true,
        },
    },
    data() {
        return {
            buttons: [
                { text: 'Adicionar', type: 'submit' },
            ],
            searchSupervisor: '',
            name: '',
            user: null,
            usersList: [],
        };
    },
    computed: {
        users() {
            return this.$store.state.users.all
        },
        groupSupervisor() {
            if (this.searchSupervisor) {
                return this.users.filter((supervisor) => {
                    let search = this.searchSupervisor.toString().toLocaleLowerCase()
                    let includesName = false
                    if( supervisor.name ) includesName = supervisor.name.toLowerCase().includes(search)
                    let includesNr = false
                    if( supervisor.nr_classificador) includesNr = supervisor.nr_classificador.toLowerCase().includes(search)
                    return includesName || includesNr
                }).slice(0, 5)
            } else {
                return []
            }
        },
        newGroupData() {
            if (this.user == null) {
                return null
            }
            return {
                name: this.name,
                user_id: this.user.id
            }
        },
    },
    methods: {
        addSupervisor(user) {
            this.user = user
        },
        removeSupervisor(user) {
            this.usersList = this.usersList.filter(users => users !== user)
        }
    },
    mounted() {
        this.$store.dispatch("users/getAll");
    }
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

.usersList {
    span {
    }
}

</style>