<template>
    <ContainerCard :columns=columns>
        <p v-for="exam in exams" :key="exam.id" class="list">
            <router-link :to="{ name: 'ExamInfo', params: { id: exam.id } }">
                <div class="exams">
                    <p>{{ exam.name }}</p>
                    <p>{{ exam.starts_at }}</p>
                    <p>{{ exam.ends_at }}</p>
                    <p>{{ exam.progress }}</p>
                    <span v-if="isAdmin" @click.prevent="$store.dispatch('exams/delete', exam.id)">Remover</span>
                </div>
            </router-link>
        </p>
    </ContainerCard>
</template>
<script>
import ContainerCard from '../ContainerCard/ContainerCard.vue';

export default {
    components: {
        ContainerCard,
    },
    data() {
        return {
            columns: [
                { text: 'Nome' },
                { text: 'Início a' },
                { text: 'Fim a' },
                { text: 'Conclusão' },
            ],
        };
    },
    computed: {
        exams() {
            return this.$store.state.exams.all;
        },
        currentUser() {
            return this.$store.state.currentUser
        },
        isAdmin() {
            return this.currentUser.role === 1
        },
    },
    mounted() {
        this.$store.dispatch('exams/getAll')
    },

}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.list {
    margin: 0;
    padding-left: 1rem;
    border-top: 1px solid gray;

    &:hover {
        background: rgba($primary-color, 0.1);

    }

    .exams {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(5, 1fr);

        span {
            margin-top: 15px;
            color: red;

            &:hover{
                margin-top: 12px;
                font-size: larger;
            }
        }
    }

}
</style>