import { createStore } from "vuex";
import classifiers from "./classifiers.js";
import users from "./usersModule.js";
import schools from "./schoolsModule.js";
import exams from "./examsModule.js";
import examItems from './exams-itemsModule.js'
import itemCodes from "./exams-itemCodesModule.js";
import examGroups from "./exams-groupsModule.js";
import examDistribution from "./exams-distributionModule.js";
import itemClassification from "./exams-itemsClassification.js";
import axios from "axios";
axios.defaults.headers = { Accept: "application/json" };

const store = createStore({
  state: {
    axios: axios,
    currentUser: null,
    currentUserError: null,
    checkedForUserInLocalStorage: false,
    authorized: false,
    afterLoginRoute: "/",
  },
  getters: {
    token(state) {
      if (state.currentUser == null) {
        return null;
      }
      return state.currentUser.api_token;
    },
  },
  mutations: {
    clear(state) {
      state.afterLoginRoute = "/"
    },
    setAfterLoginRoute(state, route) {
      state.afterLoginRoute = route
    },
    checkLocalStorage(state) {
      state.checkedForUserInLocalStorage = true
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
      state.checkedForUserInLocalStorage = true;
      if (user != null) {
        localStorage.setItem("user", JSON.stringify(user));
        state.axios.defaults.headers["Authorization"] =
          "Bearer " + user.api_token;
      } else {
        delete state.axios.defaults.headers.Authorization;
      }
    },
  },
  actions: {
    async getUser({ state, dispatch }) {
      try {
        await state.axios.get(
          `${process.env.VUE_APP_API_HOST}/user`
        );
      } catch (error) {
        console.log('ERROR: ' + error)
        dispatch('logout')
      }
    },
    getUserFromLocalStorage({ commit }) {
      const user = JSON.parse(localStorage.getItem("user"));
      commit("setCurrentUser", user);
    },
    async loginUser({ commit, state }, { email, password }) {
      const credentials = {
        email,
        password,
      };
      try {
        const results = await state.axios.post(
          `${process.env.VUE_APP_API_HOST}/login`,
          credentials
        );
        console.log(results);
        commit("setCurrentUser", results.data.data);
      } catch (error) {
        console.log(error);
        console.log("ERROR: " + error.results);
      }
    },
    async loginWithToken({ commit, state }, token) {
      try {
        const res = await state.axios.post(
          `${process.env.VUE_APP_API_HOST}/login/token`,
          {token}
        )
        commit("setCurrentUser", res.data.data);
        console.log(res.data)
      } catch(error) {
        console.log(error)
      }
    },
    async logout({ commit }) {
      commit('setCurrentUser', null)
      commit('clear')
      commit('classifiers/clear')
      commit('exams/clear')
      commit('examDistribution/clear')
      commit('examGroups/clear')
      commit('examItems/clear')
      commit('itemClassification/clear')
      commit('itemCodes/clear')
      commit('users/clear')
      localStorage.removeItem("user")
    },
  },
  modules: {
    classifiers,
    exams,
    examDistribution,
    examGroups,
    examItems,
    itemClassification,
    itemCodes,
    users,
    schools,
  },
});

export default store;
