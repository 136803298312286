<template>
    <form class="form-login" @submit.prevent="$store.dispatch('loginUser', {email, password})">
        <template v-if="token == null">
            <input autofocus v-model="email" placeholder='Email' required type="email" />
            <input v-model="password" placeholder='Senha' required type="password" />
            <div class="form-checkbox-container">
                <input type="checkbox">
                <span>Lembrar-me</span>
            </div>
            <button class="form-submit" type="submit">{{ button }}</button>
        </template>
        <template v-else>
            <div class="loading loading-error" v-if="error">
                {{ error }}
            </div>
            <div class="loading" v-else>
                A entrar...
            </div>
        </template>
    </form>
</template>


<script>

export default {
    props: ['button'],
    data() {
        return {
            email: "",
            password: "",
        };
    },
    computed: {
        token() {
            return this.$router.currentRoute.value.query.token
        },
        error() {
            return this.$store.state.currentUserError
        },
    },
}
</script>


<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.loading {
    color: $white;
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
    margin: 1em 0;
    text-transform: uppercase;
}


.form {
    &-login {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 10px;
        width: 100%;

        input:not([type=checkbox]) {
            border: none;
            width: 100%;
            height: 3em;
            padding: 0 1em;
            border-bottom: 4px solid rgba($black, 0.6);
            background: none;
            margin-top: 10px;
            outline: none;
            color: $white;

            &:hover {
                border-bottom: 4px solid $white;
                transition: 0.2s border-color ease-out;
            }

        }

    }

    &-checkbox-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 5em;
    }

    &-submit {
        width: calc(100% + 20px);
        color: $primary-color;
        background-color: rgba(255, 255, 255, 0);
        text-transform: uppercase;
        border-color: transparent;
        border-top: 1px solid $primary-color;
        z-index: 1;
        cursor: pointer;
        transition-duration: 0.4s;
        line-height: 1;
        height: 2em;
        padding: .1em 1em .2em;
        text-align: center;

        &:hover {
            background-color: rgba(255, 255, 255, 0.261);
        }
    }
}
</style>