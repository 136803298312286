<template>
    <div class="field">
        <label for="role">{{ label }}</label>
        <select id="role" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)">
            <option disabled value="">{{ choose }}</option>
            <option v-for="option in options" :key="option.value" :value="option.value">
                {{ option.text }}
            </option>
        </select>
    </div>
</template>
<script>
export default {
    props: {
        options: {
            default: ''
        },
        label: {
            type: String,
            default: '',
        },
        modelValue: {
            default: '',
        },
        selected: {
            default: '',
        },
        choose: {
            default: '',
        },

    },
    emits: [
        'update:modelValue',
    ],
}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';

.field {
    margin-bottom: 1.5em;

    #role {
        color: $black;
        margin: 0;
        width: 100%;
        height: 3em;
        padding: 0 1em;
        outline: none;
        appearance: none;
        border: none;
        font-size: 100%;
        margin: 0;
        border-bottom: 4px solid $secondary-color;

        &:focus {
            border-bottom: 4px solid $primary-color;
            transition: border-color .2s ease-out;

        }


    }
}
</style>