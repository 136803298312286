<template>
    <a href="#"
        @click.prevent="$emit('select', $event)"
        :class="{ selected: selected }" @mouseleave="closeSettings" @mouseenter="stopCloseSettings">
        <slot></slot>
        <IconComponent icon="menu" v-show="selected"
            @click.prevent.stop="settings = true"
        ></IconComponent>
        <FloatingOptions
            class="settings"
            v-if="settings && selected"
            :options="[
                {text: 'Editar'},
                {text: 'Atribuir a outro'},
                {text: 'Trocar'},
            ]"
        ></FloatingOptions>
    </a>
</template>

<script>
import FloatingOptions from '@/components/FloatingOptions.vue';
import IconComponent from '@/assets/images/IconComponent.vue';

export default {
    props: {
        selected: {required: true},
    },
    components: {
        FloatingOptions,
        IconComponent,
    },
    data() {
        return {
            settings: false,
            settingsTimeout: null,
        }
    },
    methods: {
        closeSettings() {
            this.settingsTimeout = setTimeout( () => {
                this.settings = false
            }, 500)
        },
        stopCloseSettings() {
            clearTimeout(this.settingsTimeout)
        },
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
}

</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

a, input {
    width: 100%;
    height: 3.5em;
    border: 2px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;
}
input {
    border-color: black;
    &:focus {
        outline: $primary-color;
    }
}
a {
    border-color: $primary-color;
    font-weight: bold;
    color: rgba(black, 0);
    position: relative;
    &.selected {
        color: rgba(black, 1);
        background-color: rgba($primary-color, .5);
        &:hover {
            svg {
                opacity: 1;
            }
        }
    }
}

svg {
    height: 1.5rem;
    position: absolute;
    right: .5rem;
    fill: black;
    opacity: 0;
    transition: .2s ease-out;
}

</style>