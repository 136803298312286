<template>
    <BubbleContent :buttons=addItemButton title="Adicionar Item"
        @submit.prevent="$store.dispatch('examItems/create', { data: newItemData, examId: id })">
        <template #form>
            <InputContent label="Item" v-model="name" />
            <div>
                <p>Colunas</p>
                <div>
                    <span v-for="itemColumn in itemColumns" v-text="itemColumn" :key="itemColumn.id"
                        @click.prevent="removeColumn(itemColumn)">
                    </span>
                </div>
                <p>Coluna</p>
                <input type="text" v-model="searchQuery">
                <div>
                    <p v-for="col in columnItems" :key="col" @click="addColumn(col)">
                        <span>{{ col }}</span>
                    </p>
                </div>
            </div>
            <InputContent label="Indíce de Esforço" type='number' v-model="effort" />
            <InputContent label="Sobreposição" v-model="overflow" type='number' />
            <InputContent label="A Classificar por Supervisores" type='number' v-model="supervisor_distribution" />
            <div class="radio-container">
                <InputRadio name="option" class="radio-option" label="LaTex" v-model="latex" value="1" />
                <InputRadio name="option" class="radio-option" label="Text" v-model="latex" value="0" />
            </div>
        </template>
    </BubbleContent>
</template>
<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';
import InputContent from '../../Inputs/InputContent.vue';
import InputRadio from '../../Inputs/InputRadio.vue';


export default {
    props: {
        id: {
            required: true,
        },
        columns: { required: true },
    },
    components: {
        BubbleContent,
        InputContent,
        InputRadio,
    },
    data() {
        return {
            name: '',
            effort: 1,
            overflow: 10,
            supervisor_distribution: 100,
            latex: '0',
            itemColumns: [],
            searchQuery: "",
            addItemButton: [
                { text: 'Adicionar', type: 'submit' },
            ],
        };
    },
    computed: {
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        allColumns() {
            return this.columns
        },
        newItemData() {
            return {
                name: this.name,
                effort: this.effort,
                columns: this.itemColumns,
                overflow: this.overflow,
                supervisor_distribution: this.supervisor_distribution,
                latex: this.latex,
            }
        },
        columnItems() {
            if (this.searchQuery) {
                return this.allColumns.filter((column) => column.toLowerCase().includes(this.searchQuery.toString().toLowerCase())).slice(0, 5)
            } else {
                return []
            }
        },
    },
    methods: {
        addColumn(col) {
            this.itemColumns.push(col);
        },
        removeColumn(column) {
            this.itemColumns = this.itemColumns.filter(itemColumn => itemColumn !== column)
        },
    },
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';


.radio-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: -1px;

    .radio-option {
        margin: 0;
        text-align: center;
    }
}

span {
    cursor: pointer;
}
</style>