<template>
    <div class="classifications-content">
        <div class="classifications-distribution-info">
            <p class="info-group">Grupo: </p>
            <span class="info-group-name" v-if="group">{{ group.name }}</span>
            <p class="info-group">Supervisor:</p>
            <span class="info-group-name" v-if="supervisor">{{ supervisor.name }}</span>
        </div>
        <p class="classifications-items-count" v-for="item in items" :key="item.id">
                <span>{{ item.multiple_params }}</span> 
                <!-- check the right parameter -->
            </p>
        <p class="classifications-items-count">Value</p>
        <p class="classifications-items-count">Value</p>
    </div>
    <div>
        <p class="classifications-classifiers" v-for="classifier in classifiers" :key="classifier.id">
            <span class="classifier">{{ classifier.name }}</span>
            <span class="classifiers-inputs" v-for="item in examItems" :key="item.id">
                <ExamItemsClassifications :id="id"/>
            </span>
            <span>Valor</span>
            <span v-if="items">{{ items[0].effort }}</span>
        </p>
    </div>
</template>
<script>
import ExamItemsClassifications from './ExamItemsClassifications.vue'

export default {
    props: {
        id: {
            required: true,
        },
    },
    components: {
        ExamItemsClassifications,

    },
    data() {
        return {
            select_all: false,
            attributions: {}
        }
    },
    methods: {
        attributeItem(checked, item, classifier) {
            if (this.attributions[item] == null) {
                this.attributions[item] = []
            }
            if (checked) {
                this.attributions[item].push(classifier)
                return
            }
            const index = this.attributions[item].findIndex((id) => id == classifier)
            if (index < 0) {
                return
            }
            this.attributions[item].splice(index, 1)
        },
    },
    computed: {
        classifiers() {
            if (this.group) {
                return this.$store.getters['users/withId'](this.group.users)
            }
            return []
        },
        examItems() {
            if (this.group) {
                return this.$store.getters['examItems/byExamId'](this.group.exam_id)
            }
            return []
        },
        items() {
            return this.$store.state.examItems.all
        },
        group() {
            return this.$store.getters['examGroups/getId'](this.id)
        },
        supervisor() {
            if (this.group) {
                return this.$store.getters['users/getId'](this.group.user_id)
            }
            return null
        },
    },
    watch: {
        attributions: {
            deep: true,
            handler() {
                this.$emit("update", this.attributions)
            }
        }
    },
    mounted() {
        this.$store.dispatch("users/getAll");
    },

}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';


.classifications-content {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;



    .classifications-distribution-info {
        margin: 5px 0;


        .info-group {
            font-weight: bold;
            margin: 0;

            .info-group-name {
                font-weight: lighter;
            }
        }
    }

    .classifications-items-count{
        display: flex;
        align-items: center;
    }
}

.classifications-classifiers {
    border-bottom: 1px solid $dark-border-color;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    margin: 10px 0 0 0;
    align-items: center;

    .classifier {
        display: flex;
        align-items: center;
    }

}
</style>