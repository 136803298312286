<template>
    <div class="content-items-edit" v-if="item">
        <BubbleContent :buttons=addItemButton :title="item.name" icon="sdfd"
            @submit.prevent="$store.dispatch('examItems/edit', { exam: id, itemId: itemId, item: ItemData })">
            <template #form>
                <InputContent label="Item" v-model="name" />
                <InputContent label="Indíce de Esforço" type='number' v-model="effort" />
                <InputContent label="Sobreposição" v-model="overflow" type='number' />
                <InputContent label="A Classificar por Supervisores" type='number' v-model="supervisor_distribution" />
                <InputFile label="Adicionar Ficheiro" />
                <div class="radio-container">
                    <InputRadio name="option" class="radio-option" label="LaTex" v-model="latex" value="1" />
                    <InputRadio name="option" class="radio-option" label="Text" v-model="latex" value="0" />
                </div>
                <div>
                    <p>Colunas</p>
                    <div>
                        <p v-for="itemColumn in itemColumns" :key="itemColumn.id">
                            <span>{{ itemColumn }} -</span>
                            <span class="delete" @click.prevent="removeColumn(itemColumn)"> Apagar</span>
                        </p>
                    </div>
                    <p>Adicionar Coluna</p>
                    <input type="text" v-model="searchQuery">
                    <div>
                        <p v-for="col in columnItems" :key="col" :value="col" @click="addColumn(col)">
                            <span>{{ col }}</span>
                        </p>
                        <br>
                    </div>
                </div>
            </template>
        </BubbleContent>
        <br>
        <ItemsCodes :itemId="itemId" />
    </div>
</template>
<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';
import InputContent from '../../Inputs/InputContent.vue';
import InputFile from '../../Inputs/InputFile.vue';
import InputRadio from '../../Inputs/InputRadio.vue';
import ItemsCodes from './ItemsCodes/ItemsCodes.vue';
// import IconComponent from '@/assets/images/IconComponent.vue'


export default {
    components: {
        BubbleContent,
        InputContent,
        InputFile,
        InputRadio,
        ItemsCodes,
        // IconComponent,
    },
    props: {
        itemId: {
            required: true
        },
        id: {
            required: true, //This id is to get the exam id
        },
    },
    data() {
        return {
            name: '',
            effort: 1,
            overflow: 10,
            supervisor_distribution: 100,
            latex: "0",
            itemColumns: [],
            searchQuery: "",
            addItemButton: [
                { text: 'Gravar', type: 'submit' },
            ],
        };
    },
    computed: {
        allColumns() {
            if (this.exam) {
                return this.exam.columns
            }
            return []
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        item() {
            return this.$store.getters['examItems/getId'](this.itemId)
        },
        ItemData() {
            return {
                name: this.name,
                effort: this.effort,
                columns: this.itemColumns,
                overflow: this.overflow,
                supervisor_distribution: this.supervisor_distribution,
                latex: this.latex,
            }
        },
        columnItems() {
            if (this.searchQuery) {
                return this.allColumns.filter((column) => column.toLowerCase().includes(this.searchQuery.toString().toLowerCase())).slice(0, 5)
            } else {
                return []
            }
        },
    },
    methods: {
        addColumn(col) {
            this.itemColumns.push(col);
        },
        removeColumn(column) {
            this.itemColumns = this.itemColumns.filter(itemColumn => itemColumn !== column)
        },
    },
    watch: {
        // o que inicia
        item: {
            immediate: true,
            handler() {
                if (this.item != null) {
                    this.name = this.item.name
                    this.effort = this.item.effort
                    this.itemColumns = (typeof this.item.columns == "string") ? [this.item.columns] : this.item.columns
                    this.overflow = this.item.overflow
                    this.supervisor_distribution = this.item.supervisor_distribution
                    this.latex = this.item.latex.toString()
                }
            },
        }
    },
    mounted() {
        if (this.item == null) {
            this.$store.dispatch('examItems/getId', { exam: this.id, id: this.itemId })
        }
    },
}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';

.content-items-edit {
    margin: 20px;

    .material-icons {
        width: 20px;
        fill: rgba($black, 0.8);
    }

    .radio-container {
        display: flex;
    }

}

.delete {
    color: red;
    cursor: pointer;
}
</style>