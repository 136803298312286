<template>
    <div class="content-exams-edit">
        <BubbleContent class="exams-edit-edit" title="Editar" :buttons=buttons @submit="$store.dispatch('exams/edit', { id, exam: ExamData })">
            <template #form>
                <InputContent label="Nome" v-model="name" />
                <InputContent label="Tabela" v-model="table" />
                <InputContent label="Coluna Id de Aluno" v-model="studentId" />
                <InputSelectContent label="Tipo" :options="options" choose="Selecctionar Tipo" v-model="kind" />
                <InputContent label="Começo" type="datetime-local" v-model="starts_at" :choose="date" />
                <InputContent label="Fim" type="datetime-local" v-model="ends_at" :choose="date" />
            </template>
        </BubbleContent>
        <br>
        <BubbleContent class="exams-edit-team" title="Equipa IAVE">
            <template #form>
                <InputContent label="Utilizador" v-model="utilizador" />
                <div>
                    <h3>Utilizadores</h3>
                    <div>
                        <p>Nome</p>
                        <p>Classificações</p>
                    </div>
                </div>
            </template>
        </BubbleContent>

        <BubbleContent class="exams-edit-import" title="Importar" :buttons="importButtons" @submit="importFiles">
            <template #form>
                <InputFile button="Respostas" label="Respostas" v-model="importFile" />
                <InputFile button="Anexo" label="Anexo" v-model="importFileAttachments" />
            </template>
        </BubbleContent>

        <BubbleContent class="exams-edit-upload" title="Importar" :buttons="[{text:'Upload'}]" @submit="upload">
            <template #form>
                <InputFile button="Anexos" label="Anexos" v-model="uploadFiles" />
                <div class="files" v-if="uploading.length">
                    <h3 v-text="uploadingText"></h3>
                    <ul>
                        <li v-for="file in uploading" :key="file.file.name">
                            {{ file.file.name }} - {{ file.status }}
                        </li>
                    </ul>
                </div>
            </template>
        </BubbleContent>

        <BubbleContent class="exams-edit-export" title="Exportar" type="file" :buttons="exportButtons" @submit="startExport">
            <template #form>
                <div class="files">
                    <ul>
                        <li v-for="file in examExportFiles" :key="file.id">
                            <a v-text="file.filename"
                                target="_blank"
                                href="#"
                                @click.prevent="download(file)">
                            </a>
                            <span v-if="examExportFilesDownload.includes(file.id)"> - a descarregar</span>
                        </li>
                    </ul>
                </div>
            </template>
        </BubbleContent>

    </div>
</template>
<script>
import BubbleContent from '../BubbleContent/BubbleContent.vue';
import InputContent from '../Inputs/InputContent.vue';
import InputSelectContent from '../Inputs/InputSelectContent.vue';
import InputFile from '../Inputs/InputFile.vue';
import moment from 'moment'

export default {
    components: {
        BubbleContent,
        InputContent,
        InputSelectContent,
        InputFile,
    },
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            name: "",
            table: "",
            studentId: "",
            kind: '',
            starts_at: '',
            ends_at: '',
            utilizador:'',
            buttons: [
                { text: 'Editar', type: 'submit' },
            ],
            options: [
                { text: 'Normal', value: '0' },
                { text: 'Práctica', value: '1' },
            ],
            date: new Date().toLocaleString(),
            importFile: null,
            importFileAttachments: null,
            uploadFiles: null,
            uploading: [],
            uploaded: false,
            exportButtons: [
                { text: 'Criar', type: 'submit' },
                { text: 'Atualizar', type: 'submit', click: this.refreshExportFiles},
            ],
        };
    },
    methods: {
        download(file) {
            this.$store.dispatch('exams/download', { id: this.id, file})
        },
        refreshExportFiles() {
            this.$store.dispatch('exams/getExportFiles', this.id)
        },
        startExport() {
            this.$store.dispatch('exams/export', this.id)
        },
        importFiles() {
            this.$store.dispatch('exams/import', {
                id: this.id,
                file: this.importFile ? this.importFile[0] : null,
                attachment: this.importFileAttachments ? this.importFileAttachments[0] : null,
            })
        },
        async upload() {
            this.uploaded = false
            this.uploading = []
            for( let file of this.uploadFiles ) {
                this.uploading.push({
                    file,
                    status: 'Em espera',
                    complete: false,
                    error: false,
                })
            }
            for( let file of this.uploading ) {
                let formData = new FormData()
                formData.append("upload", file.file)
                try {
                    file.status = "A carregar..."
                    let res = await this.$store.state.axios.post(
                        `${process.env.VUE_APP_API_HOST}/exams/${this.id}/upload`,
                        formData,
                        {headers: {
                            'Content-Type': 'multipart/form-data'
                        }}
                    );
                    file.status = res.data.message
                    file.complete = true
                } catch(e) {
                    console.log(e)
                    file.status = e.response.data.message
                    file.error = true
                }
                this.uploaded = true
            }
        },
    },
    computed: {
        uploadingText() {
            if( this.uploaded ) {
                return "Carregado"
            }
            return "A Carregar"
        },
        importButtons() {
            if( this.exam.task_pinged_at != null ) {
                return []
            }
            return [{text: 'Importar', type: 'submit'}]
        },
        exam() {
            return this.$store.getters['exams/getId'](this.id)
        },
        startsAtDate() {
            return moment(this.starts_at).format("YYYY-MM-DD HH:mm:ss")
        },
        endsAtDate() {
            return moment(this.ends_at).format("YYYY-MM-DD HH:mm:ss")
        },
        ExamData() {
            return {
                name: this.name,
                table: this.table,
                student_col:  this.studentId,
                kind: this.kind,
                starts_at: this.startsAtDate,
                ends_at: this.endsAtDate,
            }
        },
        examExportFiles() {
            return this.$store.getters['exams/exportFiles'](this.id)
        },
        examExportFilesDownload() {
            return this.$store.state.exams.examExportFilesDownload
        },
    },
    watch: {
        exam: {
            immediate: true,
            handler() {
                if (this.exam != null) {
                    this.name = this.exam.name
                    this.table = this.exam.table
                    this.studentId = this.exam.student_col
                    this.kind = this.exam.kind
                    this.starts_at = this.exam.starts_at
                    this.ends_at = this.exam.ends_at
                }
            },
        }
    },
    mounted() {
        if (this.exam == null) {
            this.$store.dispatch('exams/getId', this.id)
        }
        this.refreshExportFiles();
    },

}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';


.content-exams-edit {
    margin: 20px;
    color: $black;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
        "exams-edit-edit exams-edit-team"
        "exams-edit-import exams-edit-import" 
        "exams-edit-export exams-edit-export"
        "exams-edit-upload exams-edit-upload";

        .exams-edit-upload {
            grid-area: exams-edit-upload;
        }

        .exams-edit-edit {
        grid-area: exams-edit-edit;
    }

        .exams-edit-team {
        grid-area: exams-edit-team;
    }
        .exams-edit-import {
        grid-area: exams-edit-import;
    }
        .exams-edit-export {
        grid-area: exams-edit-export;
    }
    // :deep .field {
    //     margin-bottom: 1.5em;

    //     #role {
    //         color: $black;
    //         margin: 0;
    //         width: 100%;
    //         height: 3em;
    //         padding: 0 1em;
    //         outline: none;
    //         appearance: none;
    //         border: none;
    //         font-size: 100%;
    //         margin: 0;
    //         border-bottom: 4px solid $secondary-color;

    //         &:focus {
    //             border-bottom: 4px solid $primary-color;
    //             transition: border-color .2s ease-out;

    //         }


    //     }
    // }

}
</style>