<template>
    <BubbleContent 
        title="Importar Classificadores" :buttons="button" @submit.prevent="submit">
        <InputFile label="Ficheiro" v-model="files"></InputFile>
    </BubbleContent>
</template>
<script>
import BubbleContent from '../../BubbleContent/BubbleContent.vue';
import InputFile from '@/components/SCOI/Inputs/InputFile.vue'

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        BubbleContent,
        InputFile,
    },
    data() {
        return {
            button: [
                { text: 'Importar', type: 'submit' },
            ],
            files: [],
        };
    },
    methods: {
        submit() {
            this.$store.dispatch("classifiers/import", {file: this.file, examId: this.id})
        }
    },
    computed: {
        file() {
            return this.files[0]
        },
    },
    watch: {
    },
    mounted() {
    },

}
</script>
<style lang="scss" scoped>
@import '../../../../assets/utilities/_variables.scss';
</style>

