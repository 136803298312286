<template>
    <BubbleContent title="Códigos">
        <p>Se este Item de classificação tem vários parâmetros de classificação selecione o botão abaixo.</p>
        <p>Aqui pode ver todos os códigos atríbuidos a este Item de classificação.
            Para editar um código, selecione o código pretendido, altere os parâmetros do código nas caixas abaixo e
            clique no botão 'Alterar código'
            Para eliminar um código, selecione o código pretendido, apague o texto do código e clique em 'Eliminar
            código'</p>
        <ItemCodesList :itemId="itemId" :multi="multi"/>
        <ItemCodesCreate :itemId="itemId" :multi="multi" v-if="!multi"/>
        <button @click.prevent="toogleMulti" :class="{multi}">Multiparâmetro</button>
    </BubbleContent>
    <br>
    <BubbleContent title="Restrições de Classificação">
        <ItemsRestrictions :itemCodes="itemCodes" :itemId="itemId" />
    </BubbleContent>
</template>
<script>
import BubbleContent from '@/components/SCOI/BubbleContent/BubbleContent.vue';
import ItemCodesList from './ItemCodesList.vue';
import ItemCodesCreate from './ItemCodesCreate.vue';
import ItemsRestrictions from './ItemsRestrictions.vue';

export default {
    components: {
        BubbleContent,
        ItemCodesList,
        ItemCodesCreate,
        ItemsRestrictions,
    },
    props: {
        itemId: {
            required: true,
        },
    },
    data() {
        return {
            multi: false,
        };
    },
    computed: {
        itemCodes() {
            return this.$store.getters['itemCodes/getId'](this.itemId)
        },
    },
    methods: {
        toogleMulti() {
            this.multi = !this.multi
        },
    },
    watch: {
        itemCodes: {
            immediate: true,
            handler() {
                if( this.itemCodes.length ) {
                    if( this.itemCodes[0].name != null ) {
                        this.multi = true
                    }
                }
            }
        },
    },
    mounted() {
        this.$store.dispatch('itemCodes/getAll', { item: this.itemId })
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/utilities/_variables.scss';

button {
    background: transparent;
    border: 1px solid;
    width: 100%;
    padding: .25em;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    color: $primary-color;
    &.multi {
        color: $white;
        background: $primary-color;
        cursor: pointer;
    }
}
</style>