import SchoolsPage from "../views/SchoolsPage.vue";
import SchoolsList from "../components/SCOI/TabSchools/SchoolsList.vue";
import SchoolEdit from "../components/SCOI/TabSchools/SchoolEdit.vue";
import SchoolCreate from "../components/SCOI/TabSchools/SchoolCreate.vue";

const schools = [
  {
    path: "/schools",
    component: SchoolsPage,
    children: [
      {
        path: "",
        name:'SchoolsList',
        component: SchoolsList,
      },
      {
        path: ":id",
        name: "SchoolEdit",
        component: SchoolEdit,
        props: true,
      },
      {
        path: "create",
        name: "SchoolCreate",
        component: SchoolCreate,
      },
    ],
  },
];

export default schools;