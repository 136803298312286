<template>
    <div class="container">
        <div class="tableH">
            <p v-for="column of columns" :key="column.text">{{ column.text }}</p>
        </div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: ['columns'],

}
</script>
<style lang="scss" scoped>
@import '../../../assets/utilities/_variables.scss';


.container {
    height: 100%;
    padding: 20px;
    margin: 20px;
    background: $white;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
   

    position: relative;
    flex-grow: 1;
    align-self: flex-start;

    .tableH {
        padding: 1rem;
        border-bottom: 2px solid $primary-color;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        p{
            margin: 0;
            font-weight: bold;
        }
    }

}
</style>