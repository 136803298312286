<template>
    <ExamInfoSupervisor v-if="supervisor" :id="id" />
    <br>
    <ExamInfoClassifiers v-if="classifiers" :id="id" />
    <br>
</template>

<script>
import ExamInfoSupervisor from './ExamInfoSupervisor.vue';
import ExamInfoClassifiers from './ExamInfoClassifiers.vue';

export default {
    props: {
        id: {
            required: true,
        }
    },
    components: {
        ExamInfoSupervisor,
        ExamInfoClassifiers,

    },
    computed: {
        classifiers() {
            if (this.group) {
                return this.$store.getters['users/withId'](this.group.users)
            }
            return []
        },
        group() {
            return this.$store.getters['examGroups/getId'](this.id)
        },
        supervisor() {
            if (this.group) {
                return this.$store.getters['users/getId'](this.group.user_id)
            }
            return null
        },
    },

}
</script>

<style lang="scss" scoped></style>